$white: #FFFFFF;
$alabaster: #F9F9F9;
$authbg: #E5E5E5;
$shamrock: #37C597;
$primaryColor:#37C597;
$primaryMedium:#3BE8B0;
$primaryMediumLight:#3AF7D1;
$pickledBluewood: #2D3B55;
$placeHolder: #E1E2E4;
$inputColor:#2D3B55;
$inputBorderColor :#CECECE;
$passwordLinkColor:rgba(53,53,53,0.64);
$transparent:transparent;
$neutralColor:#49505A;
$neutralColor800:#686C77;
$mainBgColor:#EFF0F2;
$leftBgColor: rgba(99, 100, 102, 0.16);
$boxShadow: rgba(0, 0, 0, 0.16);
$atagGrey: #686C77;
$borderTop:rgba(224,224,224,0.7);
$lightBackground:rgba(55,197,151,0.16);
$warningBorder:#FE9A20;
$yellowMedium:#FFB929;
$warningBackground:rgba(254,154,32,0.16);
$errorColor:#E73B3B;
$redColor: #E73B3B;
$redMedium:#FC636C;
$errorBackgroundColor:rgba(231,59,59,0.16);
$tableBorderColor: #EBFCF6;
$antPagination: #D9D9D9;
$blueColor : #1890ff;
$greyColor: #898E94;
$purpleDark:#504DA8;
$purpleMedium:#6666CE;
$purpleLight:#A178FC;


/*******************************************figma*colors*plete**********************************************/

$Green500:#37C597;
$Green400:#3BE8B0;
$Green300:#3AF7D1;
$Green200:#9DFCE8;
$Green100:#EBFCF6;

$Purple500:#504DA8;
$Purple400:#6666CE;
$Purple300:#A178FC;
$Purple200:#D0BBFC;
$Purple100:#F0F0FA;

$Blue500:#169DBA;
$Blue400:#00BEE5;
$Blue300:#01CEFD;
$Blue200:#80E6FD;
$Blue100:#E8F7FA;

$Coral500:#E73B3B;
$Coral400:#FC636C;
$Coral300:#FF6D91;
$Coral200:#FFB6C9;
$Coral100:#FEF0F0;

$Yellow500:#FE9A20;
$Yellow400:#FFB929;
$Yellow300:#FDD22D;
$Yellow200:#FFE880;
$Yellow100:#FFF8E5;


/*******************************************figma*colors***********************************************/







/*****************************common-background-color*************************************************/ 

.BgPrimaryColor{background-color: $primaryColor!important;}
.BgWarningBorder{background-color: $warningBorder!important;}
.BgBlueColor{background-color: $blueColor!important;}
.BgRedColor{background-color: $redColor!important;}
.BgPurpleLight{background-color: $purpleLight!important;}
.BgRedMedium{background-color: $redMedium!important;}
.BgPickledBluewood{background-color: $pickledBluewood!important;}
.BgYellowMedium{background-color: $yellowMedium!important;}
.BgPurpleDark{background-color: $purpleDark!important;}
.BgPrimaryMediumLight{background-color: $primaryMediumLight!important;}



/***************************************************************************************************/


/*****************gradiant*background*colors***********************************/
$primaryBgGradiant:linear-gradient(201.03deg, #23BDB8 7.56%, #43E794 91.99%);
$OrangeBgGradiant:linear-gradient(196.91deg, #FFC201 7.23%, #FF9801 77.51%);
$PurpilshBgGradiant:linear-gradient(200.78deg, #753EE4 8.53%, #B16CF4 91.47%);
$bluishBgGradiant:linear-gradient(200.78deg, #43D9F4 8.53%, #0196FF 91.47%);
/*****************gradiant*background*colors***********************************/


/*********************common-font-colors************************************/
.fc-pickledBluewood {color: $pickledBluewood;}
.fc-primary{color: $shamrock;}
.fc-blue{color: $blueColor;}
.fc-orabge{color: $warningBorder!important;}

/*********************common-font-colors************************************/

/*********************common-bg*classes***********************************/
.OrangeBgGradiant{background:$OrangeBgGradiant;}
.PurpilshBgGradiant{background: $PurpilshBgGradiant;}
.bluishBgGradiant{background:$bluishBgGradiant;}
.primaryBgGradiant{background:$primaryBgGradiant;}

/*********************common-bg*classes***********************************/ 

/*********************common-border*classes***********************************/ 
.border-primary-1p{ border:1px solid $primaryColor;}
.bc-primary{border-color: $primaryColor!important;}
.bc-blue{border-color: $blueColor!important;}

/*********************common-border*classes**ends*****************************/ 

/****************************global-setings***********************************/ 
.ant-switch-checked { background-color: #37c597;}
/****************************global-setings***********************************/

/****************************global-setings***********************************/
.btn-outline-primary{color:$white!important;background-color:$primaryColor!important; border-color:$primaryColor!important;
&:hover,&:focus{ border-color:$primaryColor!important;}}
.btn-primary-active{border: 2px solid $primaryColor !important; background: #fff; border-radius: 6px; font-style: normal; font-weight: 600; font-size: 14px; line-height: 1.5; line-height: 40px; text-align: center;  letter-spacing: 0.01em;  color: $primaryColor;
border: 0; min-width: 118px; padding-left: 15px; 
&:hover,&:focus{ color:$primaryColor!important;}
}

/****************************global-setings***********************************/

/****************************common*buttons**********************************/
.out-Grey-btn{ 
    font-size:0.875rem ;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 39px;
    min-width: 118.39px;
    border-radius: 6px;
    border-radius: 6px; font-style: normal; font-weight: 600;
    border: 1px solid #C5C5C6;
    color: #646464;
    padding: .3rem 1rem;
}
.out-Grey-btn:hover {color: #646464; }
/****************************common*buttons**********************************/





