// @import "~antd/dist/antd.css";
@import "./color-vars.scss";
@import "./font-vars.scss";
body {
    font-family: $muli;
}

.mainContainer {
    display: flex;
    width: 100%;
    height: 100%;
}

.clearFix {
    clear: both;
}

.container-fluid {
    width: 100%;
}

.buttonTabs.buttonhide {
    display: none;
}

.danger {
    color: red;
}

.bg-Primary-gradiant {
    background: linear-gradient(201.03deg, #23bdb8 7.56%, #43e794 91.99%), #ffffff !important;
}

.d-flex {
    display: flex;
}

.d-none {
    display: none !important;
}

.v-hidden {
    visibility: hidden;
}

.v-visible {
    visibility: visible;
}

.spaceBetween {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.height-100 {
    height: 100% !important;
}


/*----------------- common-margin----------------*/

.ml-auto {
    margin-left: auto;
}

.mb0 {
    margin-bottom: 0px !important;
}

.mt0 {
    margin-top: 0 !important;
}

.pt0 {
    padding-top: 0px !important;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.ml-0 {
    margin-left: 0 !important;
}


/*----------- common-flex -d-flex--flex-start-------*/

.flex_start_top {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}


/*----------- common-fontweight -----------*/

.FW700 {
    font-weight: 700;
}

.FW800 {
    font-weight: 800;
}


/*----------- common-fontweight ends-----------*/


/*----------- Default Form -----------*/

.mh-auto {
    max-height: auto;
}

.border0 {
    border: 0;
}

.defaultForm {
    width: 100%;
    .ant-form-item-required:before {
        display: none !important;
    }
    .ant-form-item-label>label {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: $pickledBluewood;
    }
    .ant-input,
    .ant-input-password,
    input {
        background: $white;
        border: 1px solid $inputBorderColor;
        box-sizing: border-box;
        border-radius: 6px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        padding: 12px;
        width: 100%;
    }
    .ant-input,
    .ant-input-password {
        color: $inputColor;
    }
    .ant-input-password-icon svg {
        display: none;
    }
    .anticon-eye-invisible.ant-input-password-icon {
        background-image: url(../images/forms/passwordHide.svg);
    }
    .ant-input-password-icon {
        background: url(../images/forms/passwordShow.svg) no-repeat center center;
        background-size: 24px;
        height: 24px;
        width: 24px;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: $inputBorderColor !important;
        box-shadow: none !important;
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-input:focus,
    .ant-input-focused {
        box-shadow: none !important;
    }
    .passwordLinkItem {
        position: relative;
    }
    .forgotPassword {
        position: absolute;
        right: 0;
        top: -30px;
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: $passwordLinkColor;
    }
    .updatePasswordError {
        font-weight: normal;
        font-size: 14px;
        color: red;
        line-height: 19px;
        position: absolute;
    }
     ::-webkit-input-placeholder {
        color: $placeHolder;
        font-style: italic;
    }
     ::-moz-placeholder {
        color: $placeHolder;
        font-style: italic;
    }
     :-ms-input-placeholder {
        font-style: italic;
        color: $placeHolder;
    }
     :-moz-placeholder {
        font-style: italic;
        color: $placeHolder;
    }
    .ant-btn-primary,
    .ant-btn {
        background: $primaryColor;
        border-radius: 6px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        color: $white;
        padding: 14px;
        height: auto;
        border-color: $transparent !important;
    }
    .submitButton {
        padding-top: 16px;
        margin-bottom: 30px;
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
        border-color: $transparent !important;
        box-shadow: none !important;
    }
}


/*----------- Login Page -----------*/

.login-page {
    width: 100%;
    display: flex;
    background: $white;
    .full-page {
        padding: 0;
        &>.row {
            margin: 0;
        }
    }
    .padding-no {
        padding: 0 0;
    }
    .login-img {
        background: $shamrock;
        width: 100%;
        min-height: 100vh;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .login-logo {
        width: 100%;
        // display: flex;
        // padding: 64px 0 64px 49px;
        margin-bottom: 3rem;
        img {
            max-width: 300px;
        }
    }
    .login-confirm {
        width: 100%;
        height: calc(100% - 250px);
        // display: flex;
        // align-items: center;
        // justify-content: left;
        // padding-left: 59px;
        max-width: 500px;
    }
    .login-inner .buttonMain,
    .login-inner .buttonMain :active,
    .login-inner .buttonMain:focus {
        background: $shamrock;
        border-radius: 6px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        color: $white;
        display: inline-block;
        padding: 14px;
        margin: 30px 0 40px;
        max-width: 420px;
        width: 100%;
    }
    .login-inner .buttonMain,
    .login-inner .buttonMain:focus,
    .login-inner .buttonMain:active,
    .login-inner .buttonMain:hover,
    .login-inner .btn-primary:hover,
    .login-inner .btn-primary:not(:disabled):not(.disabled).active,
    .login-inner .btn-primary:not(:disabled):not(.disabled):active {
        padding: 14px;
        line-height: 25px;
    }
    .logo-text-section {
        width: 100%;
        float: left;
        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            line-height: 44px;
            color: $pickledBluewood;
            margin-bottom: 30px;
        }
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 22px;
            line-height: 30px;
            color: $pickledBluewood;
        }
    }
    .register-link {
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        color: $pickledBluewood;
        a {
            font-weight: 700;
            color: $shamrock;
        }
        p {
            width: 412px;
            height: 30px;
        }
    }
        .activate-account.create-account{border-left:3px solid #49505A; margin-left: 10px; padding-left: 10px;}
    .activate-account {
        cursor: pointer;
        color: $shamrock;
    }
    .loginHeadings {
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            color: $neutralColor;
        }
    }
    .loginHeadings h3 {
        margin-bottom: 8px;
    }
    .activateAccountLink {
        text-align: center;
    }
    .activateAccountLink p {
        color: $neutralColor800;
    }
}


/*********** Select Box *************/

.ant-select {
    width: 100%;
}

.ant-select-selector,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: $white;
    border: 1px solid $inputBorderColor;
    box-sizing: border-box;
    border-radius: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    height: 50px;
    width: 100%;
    outline: none !important;
    box-shadow: none !important;
    color: $placeHolder;
    padding: 12px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: 1px solid $inputBorderColor !important;
    outline: 0 !important;
}

.multipleCol .ant-col {
    width: 100%;
}

// .ant-select-arrow span {
//     display: none;
// }

.ant-select-arrow {
    background: url(../images/arrow/SelectArrow.svg) no-repeat center center;
    background-size: 12px;
}

.customRowSelect {
    .ant-col {
        padding-right: 12px;
    }
    .ant-col:last-child {
        padding-right: 0;
    }
}

.borderButton {
    border: 2px solid $primaryColor;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    display: inline-block;
    align-items: center;
    text-align: center;
    padding: 12px 24px;
}

.tickYesGreen {
    background: url(../images/tickYesGreen.svg) no-repeat center center;
    background-size: 80px;
    width: 80px;
    height: 80px;
    display: inline-block;
    margin-bottom: 10px;
}


/***************Sidebar ***************/

.leftSidebar {
    background: $pickledBluewood;
    width: 200px;
    height: 100%;
    position: relative;
    nav {
        width: 100%;
        float: left;
    }
}

.mainMenu {
    width: 100%;
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        a {
            width: 100%;
            display: block;
            padding: 1.125rem 1.25rem 1.125rem 1.875rem;
            position: relative;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            color: $white;
        }
        width: 100%;
        float: left;
        a.active {
            background: $primaryColor;
        }
        &:not(.Logo) {
            &:hover {
                a {
                    background: $primaryColor;
                }
            }
        }
    }
}

// .mainContainer {
//   display: flex;
//   width: 100%;
//   height: 100%;
// }
.mainContent {
    width: calc(100% - 200px);
    float: left;
    height: 100%;
    background: $mainBgColor;
}

.iconLetBar {
    background: url(../images/Home.svg) no-repeat;
    vertical-align: middle;
    background-size: 24px;
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 4px;
}

.letSideProfile {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: auto;
    display: flex;
    background: $leftBgColor;
    cursor: pointer;
    align-items: center;
    height: 9vh;
    max-height: 83px;
}
.letSideProfileLogout{
    display: flex;
    cursor: pointer;
    align-items: center;
    position: relative;
}
.letSideProfileLogout .sidebarMenuDrop {position: absolute;
    left: -10px;
    bottom: -55px;}
.profileIcon {
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    margin: 20px 4px 20px 16px;
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-right: 8px;
}

.linkIcon {
    width: 5px;
    height: 10px;
    background: url(../images/RightArrow.svg) no-repeat center center;
    background-size: 5px;
    width: 5px;
    height: 10px;
    display: inline-block;
    position: absolute;
    right: 17.5px;
    top: 50%;
    margin-top: -5px;
}

.topNavBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: $white;
}

.iconLetBar.icon2 {
    background-image: url(../images/Transactions.svg);
}
.iconLetBar.icon8 {
    background-image: url(../images/Expenses.svg);
}

.iconLetBar.icon3 {
    background-image: url(../images/Contribution.svg);
}

.iconLetBar.icon4 {
    background-image: url(../images/MessageCenter.svg);
}

.iconLetBar.icon5 {
    background-image: url(../images/Documents.svg);
}

.iconLetBar.icon6 {
    background-image: url(../images/ForYou.svg);
}

.countLeftSide {
    background: $redColor;
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    color: $white;
    letter-spacing: 0.01em;
    position: absolute;
    top: 50%;
    right: 16px;
    margin-top: -8px;
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: 100%;
}

.containerNew {
    padding: 24px 32px;
    width: 100%;
    justify-content: space-between;
}

.leftName {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: $white;
    line-height: 16px;
}

.leftProfID {
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    color: $white;
    letter-spacing: 0.01em;
    line-height: 12px;
}

.nameIDLeft {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.actionRight {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: right;
}

.headingTop {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    margin: 0;
    color: $pickledBluewood;
}

.topNavRight {
    float: right;
}

.sidebarMenuDrop {
    background: $white;
    padding: 6px 12px;
    box-shadow: 0px 0px 6px 4px $boxShadow;
    border-radius: 4px;
    width: 180px;
    height: auto;
    position: absolute;
    left: 208px;
    bottom: 10px;
    a {
        display: block;
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: $atagGrey;
        text-align: left;
        padding: 6px 0;
    }
    a.logout {
        margin-top: 8px;
        border-top: 0.7px solid $borderTop;
        padding: 12px 0;
    }
}

.menuIconLeftSide {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(../images/LeftSidebarIcon001.svg) no-repeat center center;
    background-size: 24px;
    margin-right: 12px;
    vertical-align: middle;
}

.icon2 {
    background-image: url(../images/LeftSidebarIcon002.svg);
}

.icon3 {
    background-image: url(../images/LeftSidebarIcon003.svg);
}

.icon4 {
    background-image: url(../images/LeftSidebarIcon004.svg);
}

.icon5 {
    background-image: url(../images/LeftSidebarIcon005.svg);
}

.icon6 {
    background-image: url(../images/LeftSidebarIcon006.svg);
}

img {
    max-width: 100%;
}

#root,
body,
html {
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
}

.mainMenu li.logo div {
    padding: 22px 20px;
}

.buttonLeftInvest.active:hover,
.buttonLeftInvest.active:focus {
    background-color: #37c597;
    color: #fff;
}

.buttonMain,
.buttonMain:hover,
.buttonLeftInvest.active,
.buttonLeftInvest.active:hover,
button.buttonLeftInvest.Active,
.buttonLeftInvest.activeBorder,
.buttonLeftInvest.activeBorder:hover {
    background: $primaryColor;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $white;
    // display: flex;
    // padding: 0 42px;
    border: 0;
    min-width: 118px;
    padding-left: 15px;
    padding-right: 15px;
}

.buttonLeftInvest.active,
button.buttonLeftInvest {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 39px;
    min-width: 118.39px;
    border-radius: 6px;
}

button.buttonLeftInvest,
button.buttonLeftInvest:hover,
button.buttonLeftInvest:focus {
    background: #efefef;
    color: rgba(106, 112, 126, 0.47);
    border: 0;
}

.buttonLeftInvest.activeBorder,
.buttonLeftInvest.activeBorder:hover {
    border: 2px solid #37c597 !important;
    background: #fff !important;
    color: #37c597 !important;
    display: inline-flex;
    min-width: 118.38px;
    height: 39px;
    justify-content: center;
    align-items: center;
    min-width: 118.39px;
    font-size: 14px;
    line-height: 39px;
}


/********************** Card Section ***********************************/

.totalHeading {
    h2 {
        text-align: center;
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: 0.01em;
        color: $white;
        margin: 0;
        span {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            display: inline-block;
            margin-top: -5px;
            line-height: 24px;
            text-align: center;
            color: $white;
            position: relative;
            top: -15px;
        }
    }
    p {
        margin: 0 0 40px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $white;
        text-align: center;
    }
}

.priceBottom {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: $white;
    text-align: center;
}

.blueCardTop.flexMiddle {
    .ant-card-body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

.cardWhite.flexMiddle {
    .ant-card-body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}


.cardWhite {
    background: $white;
    height: 256px;
}

.whiteCardContent {
    h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: $pickledBluewood;
        text-align: center;
        margin: 0;
    }
    h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: $primaryColor;
        text-align: center;
        margin: 0 0 40px;
    }
    p {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: $pickledBluewood;
        text-align: center;
        margin: 0;
    }
}

.ant-row {
    margin-left: -15px;
    margin-right: -15px;
}

.ant-col {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.container-fluid {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.scrollDashboard .ant-row {
    margin-bottom: 30px;
}

.card-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: $pickledBluewood;
}

.card-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
}

.viewTransaction {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: $pickledBluewood;
    display: flex;
    align-items: center;
    .arrow {
        margin-left: 10px;
    }
}

a {
    color: $pickledBluewood;
}

.arrow {
    width: 16px;
    height: 16px;
    background: url(../images/arrowRight.svg) no-repeat center center;
    background-size: 16px;
    display: inline-block;
}

.card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: rgba(19, 23, 26, 0.8);
}

.card-header {
    margin: 0 0;
    width: calc(100%);
    padding: 0;
}


/*************** Alert Box *****************/

.ant-alert-success {
    border: 2px solid $primaryColor;
    border-radius: 6px;
    background: $lightBackground;
}

.ant-alert-success .ant-alert-icon {
    color: $primaryColor;
}

.ant-alert-with-description .ant-alert-message {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: $pickledBluewood;
}

.ant-alert-with-description .ant-alert-description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $pickledBluewood;
}

.ant-alert-warning {
    border: 2px solid $warningBorder;
    border-radius: 6px;
    background: $warningBackground;
}

.ant-alert-warning .ant-alert-icon {
    color: $warningBorder;
}

.ant-alert-error {
    border: 2px solid $errorColor;
    border-radius: 6px;
    // background: $errorBackgroundColor;
    background: #ffb6c9;
}

.ant-alert-error .ant-alert-icon {
    color: $errorColor;
}

.login-page .container-fluid .ant-row .ant-col.greenBg {
    background: $primaryColor;
}

.login .ant-input-affix-wrapper-has-feedback .input{
border: 0;
}
/****************** Ant Table *****************/

.ant-table-thead>tr>th {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $neutralColor800;
}

.ant-table-tbody {
    tr.ant-table-expanded-row {
        td {
            border: 0;
            // background: $tableBorderColor;
            // background: $tableBorderColor;
            background: #ffffff;
        }
    }
    >tr {
        >td.ant-table-row-expand-icon-cell {
            button {
                &:after {
                    display: none;
                }
                &:before {
                    display: none;
                }
                width: 8px;
                height: 14px;
                background: url(../images/arrow/ArrowRightTable.png) no-repeat center center;
                background-size: 8px;
                outline: none;
                border: 0;
                box-shadow: none;
                &:active {
                    outline: none;
                    border: 0;
                    box-shadow: none;
                }
                &:focus {
                    outline: none;
                    border: 0;
                    box-shadow: none;
                }
            }
            button.ant-table-row-expand-icon-expanded {
                background-image: url(../images/arrow/ArrowBottomTable.png);
                width: 14px;
                background-size: 14px;
            }
        }
    }
}

.heightAuto {
    height: auto;
}

.ant-table-pagination-right {
    justify-content: center;
}

.ant-pagination-item {
    a {
        border-radius: 2px;
        border: 1px solid $antPagination;
    }
}

.ant-pagination-item-active {
    a {
        border-color: $blueColor;
        color: $blueColor !important;
    }
}

.ant-pagination {
    text-align: center;
}

.ant-pagination-total-text {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
}

.paginationDisable {
    .ant-pagination {
        display: none;
    }
}

.infoIcon {
    .anticon {
        color: $neutralColor800;
        font-size: 20px;
        margin-left: 8px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
}

.defaultSelect {
    background: $alabaster;
    border: 2px solid $placeHolder;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $neutralColor;
    padding: 9px 32px 9px 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(../images/SelectArrow.svg);
    background-position: right center;
    background-repeat: no-repeat;
    border-radius: 6px;
    background: url(../images/arrow/ArrowDown.png) center right no-repeat $white;
    background-size: 32px;
}

select.defaultSelect {
    option {
        &:hover {
            background: $primaryColor;
        }
        &:checked {
            background: $primaryColor;
        }
    }
    &:focus {
        >option {
            &:checked {
                background: $primaryColor;
            }
        }
    }
    &:hover {
        option {
            background: $primaryColor;
        }
    }
}

.ant-select {
    &:not(.ant-select-customize-input) {
        .ant-select-selector {
            background: none;
            border: 0;
            background: none;
            border: 0 !important;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $neutralColor;
        }
    }
    &:not(.ant-select-disabled) {
        &:hover {
            .ant-select-selector {
                border: 0 !important;
            }
        }
    }
}

// .ant-select-focused {
//     &:not(.ant-select-disabled).ant-select {
//         &:not(.ant-select-customize-input) {
//             .ant-select-selector {
//                 border: 0;
//               //  box-shadow: none;
//             }
//         }
//     }
// }

.ant-select.defaultSelect {
    padding: 5px;
}

.ant-select-item-option-selected {
    &:not(.ant-select-item-option-disabled) {
        background: $primaryColor;
        color: $white;
    }
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active {
    background: $primaryColor;
    color: $white;
}

.ant-select-single {
    &:not(.ant-select-customize-input) {
        .ant-select-selector {
            width: 100%;
            height: 32px;
            padding: 0 11px;
            background: no-repeat;
        }
    }
}

.ant-select-selector {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $neutralColor;
}

.donutCenter {
    width: 100%;
    position: absolute;
    text-align: center;
    h2 {
        font-style: normal;
        font-weight: 900;
        margin: 0;
        font-size: 32px;
        line-height: 40px;
        color: $pickledBluewood;
        span {
            font-size: 16px;
            line-height: 24px;
            color: $pickledBluewood;
            position: relative;
            top: -18px;
        }
    }
    p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $greyColor;
    }
}

.donutChartExp {
    .donutChartCenter {
        min-height: 308.5px;
    }
}

.donutChartCenter {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    #chart {
        width: 100%;
    }
}

.chartValueText {
    width: 100%;
    float: left;
    padding: 0 0.625rem;
}

.chartValue {
    width: 50%;
    float: left;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 0.875rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: $pickledBluewood;
    span {
        vertical-align: middle;
        display: inline-block;
    }
}

.colorBox {
    border-radius: 2px;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    display: inline-block;
}

.seriesAmount {
    font-weight: 700;
}

.cardFooterTwo {
    width: 100%;
    float: left;
    clear: both;
    .footTextFlex {
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}

.footTextFlex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $pickledBluewood;
    padding: 0 0 16px;
}

.card-footer {
    width: 100%;
    float: left;
    clear: both;
    background: no-repeat;
    border-top: 0.7px solid #e0e0e0;
    padding: 8px 0 0;
    margin: 0;
}

.card-footer {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 20px;
    color: $pickledBluewood;
    display: flex;
    justify-content: space-between;
}

.card.messageCenter .card-footer.flexFooter {
    align-items: center;
}

.ant-card-body {
    padding: 20px;
}

.chartFooterList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}

// .fixedTable {
//   // min-height: 380px;
// }
.scrollDashboard {
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    float: left;
    clear: both;
    max-height: calc(100% - 100px);
    padding-bottom: 0;
}
.scrollContent {
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    float: left;
    clear: both;
    max-height: calc(100% - 10px);
    padding-bottom: 0;
}
.scrollDashboard.investmentPage {max-height: calc(100% - 80px);}
.sidebarMenuDrop {
    z-index: 999;
}

.VerificationAlerts .ant-alert {
    margin-bottom: 40px;
}

// ant-alert ant-alert-warning ant-alert-with-description
.resetPasswordPage .loginHeadings {
    margin-bottom: 15px;
}

.monthBox {
    height: 226px;
}

.monthBox h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 40px;
    color: #2d3b55;
    margin: 0;
    padding: 0;
}

.monthBox p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #2d3b55;
    margin: 0;
    padding: 0;
}

.marginBtMonth {
    margin-bottom: 24px !important;
}

.ant-notification-notice {
    padding: 30px;
    margin-bottom: 0 !important;
}

.ant-notification-notice-message {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: $pickledBluewood;
}

.ant-notification-notice-description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $pickledBluewood;
}

.ant-notification-notice-error {
    border-radius: 6px;
    border: 2px solid $errorColor;
    background: $errorBackgroundColor;
}

.ant-notification {
    background: $white;
}

.anticon.ant-notification-notice-icon-error {
    color: $errorColor;
}

.ant-notification-close-x {
    color: $redColor;
}

.ant-notification-notice-success {
    border: 2px solid $primaryColor;
    border-radius: 6px;
    background: $lightBackground;
}

.anticon.ant-notification-notice-icon-success {
    color: $primaryColor;
}

.ant-notification-notice-warning {
    border: 2px solid $warningBorder;
    border-radius: 6px;
    background: $warningBackground;
}

.anticon.ant-notification-notice-icon-warning {
    color: $warningBorder;
}

.messageCenter {
    height: auto;
}

.cardWhite.flexMiddle .ant-card-body {
    flex-direction: column;
}

.middleContent p {
    font-style: normal;
    // font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: $pickledBluewood;
}

.greyBtnDefault {
    background: #eff0f2;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000;
    padding: 12px 22px;
    border: 0;
    outline: 0;
}

.rightAngle {
    background: url(../images/RightAngle.svg) no-repeat center center;
    background-size: 12px;
    display: inline-block;
    width: 12px;
    height: 20px;
}

// .card-header{    border-bottom: 1px solid rgba(0,0,0,.125);}
.middleContent {
    min-height: 342px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.middleContentContributions
{
    min-height: 411px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.middleContentContributions.individual
{min-height: 180px;}
.middleContentContributionsLeft
{
    min-height: 360px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.middleContentExpenses
{
    min-height: 200px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.profileTabHeading {
    width: auto;
    float: left;
    display: flex;
    margin: 0px;
    padding: 0px;
    position: relative;
}

.profileTabHeading li {
    list-style: none;
    margin-right: 30px;
    border-bottom: 4px;
}
#updatePassword .ant-input-affix-wrapper input{border: 0;}
.profileTabHeading li a {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: rgb(45, 59, 85);
    text-align: center;
    padding: 8px 15px 12px;
    position: relative;
    display: block;
}

.headingTop {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    margin: 0px;
    color: rgb(45, 59, 85);
    // margin-bottom: 10px;
}

.ant-tabs-tab {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: $greyColor;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
    color: $pickledBluewood !important;
}

.ant-tabs-ink-bar {
    border-radius: 20px;
    background: $pickledBluewood;
}

.ant-tabs {
    width: 100%;
    height: 100%;
}

.toolTip {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #686c77;
    max-width: 150px;
}

.ant-table-thead>tr>th {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #686c77;
}

.autoSearch {
    width: 100%;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
   // background: url(../images/SearchIcon.png) center right no-repeat;
    padding: 10px;
    border-radius: 4px;
    background-size: 32px;
    height: 40px;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    pointer-events: none;
    padding-left: 20px;
    line-height: 30px;
}

.searchDropDown {
    width: 100%;
    display: block;
    margin: 0 0 10px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 24px;
}
.selectBoxTopBar .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    //padding-right: 20px;
}
.defaultSelect {
   // background: url(../images/ArrowDown.png) center right no-repeat #fff;
    background-size: 32px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: no-repeat;
}

.ant-select-auto-complete.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0;
    background-color: #fff;
}

.searchDropDown .selectBoxTopBar {
    text-align: right;
}

.autoCompleteSelectCont {
    width: 50%;
    float: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.autoCompleteSelectCont .selectBoxTopBar {
    float: right;
    max-width: 140px;
    margin-left: 8px;
}

.autoCompleteSelectCont .autoSearch {
    max-width: 290px;
}

.autoCompleteSelectCont .ant-select.defaultSelect {
    padding: 2px;
}

.infoStatus {
    display: flex;
    align-items: center;
}

.infoStatus img {
    margin: 0 0 0 5px;
}

.infoStatus p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: #686c77;
}

.ant-card {
    border-radius: 6px;
}

.searchDropDown {
    margin-bottom: 16px;
}

.PaginationDisable .ant-pagination.ant-table-pagination {
    display: none;
}

.transactionData h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #49505a;
    margin: 0 0 10px;
}

.listingData {
    width: 100%;
    float: left;
}

.listData {
    width: 100%;
    float: left;
}

.dateListDesc {
    display: flex;
    margin: 0 0 10px;
}

.transactionName {
    width: 15%;
}

.dateListDesc {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #898e94;
}

// td.ant-table-column-sort, .ant-table-thead th.ant-table-column-sort  {background: none;}
.ant-table-tbody>tr:nth-child(even) td {
    background: #f9f9f9;
}

.customPagination {
    margin-top: 20px;
}

.cardHeaderBorder {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.contColorCard h2 {
    color: #37c597;
}

.contColorCard {
    position: relative;
}

.bottomCardFix {
    position: absolute;
    bottom: 16px;
    text-align: center;
    width: 100%;
    left: 0;
}

.orangeCard h2 {
    color: #fe9a20;
}

.blueCardTop.flexMiddle .card-body,
.cardWhite.flexMiddle .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contColorCard {
    padding: 20px;
}

.contColorCard .card-body {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottomCardFix {
    padding: 0 20px;
}

.hsa-img {
    position: absolute;
    left: -775px;
    top: -25px;
  }
  .rect-img{
    position: absolute;
    left: -720px;
    top: -15px;
  }
  .contact-us
  {
    color: #169DBA;
    text-decoration: underline;
  }
  .login .rect-img {
    top: 30px;
  }
  .login .hsa-img {
    top: 20px;
    height: 100%;
  }
  .login .hsa-img img {
    width: 100%;
    height: 100%;
  }

// .conributionPageChart .ant-card-body{    min-height: 423px;}
.confirmCheckbox a {
    color: $primaryColor;
}

.confirmCheckbox .ant-form-item,
.ant-form-item-control-input-content {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $neutralColor800;
}

.ant-checkbox {
    margin-right: 8px;
    display: inline-block;
}

.ant-checkbox-inner {
    border: 1px solid #cecece;
    box-sizing: border-box;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    top: 5px;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background: $primaryColor;
    border-color: $primaryColor;
}

.tabsSection {
    width: 100%;
    display: block;
    float: left;
    clear: both;
}

.profileTabHeading {
    width: 100%;
    float: left;
    display: flex;
    margin: 0 0 0;
    padding: 0;
    position: relative;
}

.profileTabHeading li {
    list-style: none;
    margin-right: 30px;
    border-bottom: 4px;
}

.profileTabHeading:after {
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    content: "";
    background: #e1e2e4;
    border-radius: 10px;
    height: 4px;
    bottom: 0;
}

.profileTabHeading li a {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #2d3b55;
    text-align: center;
    padding: 8px 0 12px;
    position: relative;
    display: block;
}

.profileTabHeading li a:after {
    height: 4px;
    border-radius: 20px;
    position: absolute;
    width: 100%;
    z-index: 999;
    content: "";
    background: transparent;
    left: 0;
    bottom: 0px;
}

.profileTabHeading li a.active:after {
    background: #2d3b55;
}

.profileLeftCont {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #686c77;
}

.profileRightCont {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: #686c77;
}

.profileSection h2 {
    margin-bottom: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #2d3b55;
}

.ProContentRow {
    border-top: 0.7px solid rgba(224, 224, 224, 0.64);
    padding-top: 18px;
    padding-bottom: 16px;
}

.profileSection {
    margin-bottom: 30px;
}

.profileRightMenu {
    width: 100%;
    float: left;
    margin: 0;
    padding: 0 16px;
    list-style: none;
    border-left: 1px solid #898e94;
}

.profileRightMenu li {
    width: 100%;
    display: block;
}

.profileRightMenu li a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #2d3b55;
    text-align: left;
    padding: 16px 0;
    display: block;
}

.scrollProfile {
    padding-bottom: 0;
    overflow-x: hidden;
}

.profilePage {
    margin: 0;
}

.customScrollBar {
    overflow-x: hidden !important;
}

.scrollBarInner {
    // width: calc(100% - 17px);
    max-width: 100%;
    overflow-x: hidden;
}

.scrollViewMain {
    // max-height: calc(100% - 135px);
}

.scrollViewNoPaddingBt {
    max-height: calc(100vh - 160px);
}

.profileRightMenu {
    position: fixed;
}

.profileRightMenu li a:not([href]):not([class]),
.ProfileRightMenu li a:not([href]) {
    color: #898e94;
}

.profileRightMenu li a:not([href]).activeNew {
    color: #2d3b55;
}

.profileRightMenu li a:not([href]),
.profileRightMenu li a:not([href]):not([class]) {
    color: #898e94;
}

.ant-tabs-tab {
    padding: 12px 16px;
    text-align: center;
}

a.blueLink,
a.blueLink:hover {
    color: #504da8;
    text-decoration: underline;
    font-style: italic;
}

.addDependentModal .form-group .form-control {
    width: 100%;
    max-width: 100%;
    height: 42px;
    border-radius: 2px;
    border: 1px solid rgba(134, 141, 149, 0.24);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.addDependentModal .ant-select.DefaultSelect {
    padding: 2px;
    border-radius: 2px;
    border: 1px solid rgba(134, 141, 149, 0.24);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.addDependentModal .form-group {
    margin-bottom: 16px;
}

.ant-modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
}

.ant-modal-header {
    border: 0;
}

.ant-modal-footer {
    display: none;
}

.ant-form-item-label>label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #686c77;
}

.buttonRight {
    text-align: right;
}

.ant-modal-mask {
    background: rgba(45, 59, 85, 0.8);
}
.ant-input-affix-wrapper-has-feedback input{border: 0;}
#verificationForm .ant-input-affix-wrapper-has-feedback input, #verificationForm .ant-input-password input{border: 0;}
#addBeneficiary .ant-input-affix-wrapper-has-feedback input{border: 0;}
.defaultForm .ant-btn-primary.buttonBorder {
    border: 2px solid #37c597 !important;
    background: #fff;
    color: #37c597;
    border-radius: 6px;
    padding: 8px 20px;
}

.successModal * {
    text-align: center;
}

.successModal .anticon.anticon-check-circle {
    font-size: 72px;
    color: #37c597;
}

.successModal h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #2d3b55;
    margin: 10px 0 0;
}

.successModal p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #2d3b55;
}

.rejectModal .anticon-warning {
    font-size: 72px;
    color: #eacdb0;
}

.defaultForm .ant-btn-primary.failedBtn {
    color: #2d3b55;
    border-color: #2d3b55 !important;
}

.ant-table-tbody>tr>td {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #686c77;
}

.actionBtnTable {
    background: url(../images/dotsHorizontal.svg) no-repeat center center;
    background-size: 16px;
    display: inline-block;
    height: 16px;
    width: 16px;
}

// .tableDropDown {padding: 16px;  width: 210px; height: auto; top: 0px; left: 0; z-index: 9999;}
.dependentsTable {
    margin: 0 0 30px;
}

.ant-select {
    background: #ffffff;
    border: 1px solid #cecece;
    box-sizing: border-box;
    border-radius: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    padding: 8px 12px;
}

.dOBSelects .ant-row.ant-form-item {
    width: 33.33%;
    max-width: calc(33.33% - 8px);
    float: left;
    margin: 0;
}

.dOBSelects .ant-row.ant-form-item .ant-col {
    padding: 0 !important;
}

.dOBSelects label {
    width: 100%;
}

.dOBSelects .ant-row.ant-form-item {
    margin-right: 12px;
}

.dOBSelects .ant-row.ant-form-item:last-child {
    margin-right: 0;
}

.ant-form-item {
    margin-bottom: 18px;
}

.dOBSelects {
    margin-bottom: 18px;
    display: block;
    width: calc(100% + 15px);
    float: left;
    clear: both;
}

.defaultForm .ant-btn {
    padding: 10px 30px;
}

.defaultForm button.ant-btn-primary.dangerButton {
    background: #e73b3b !important;
    border-color: #e73b3b !important;
    border-radius: 6px;
    color: #fff;
}

.rightBtn {
    text-align: right;
}

.deleteDependent p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #49505a;
}

.buttonMain:hover,
.buttonMain:focus,
.buttonMain:active,
.buttonMain:visited,
button:focus,
button:hover,
button.buttonMain:focus,
button.buttonMain:hover {
    border-color: transparent !important;
    box-shadow: none;
    cursor: pointer;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    padding-left: 0;
    margin-top: -1px;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding-left: 0;
}

* {
    outline: none !important;
}

.ant-tabs-nav-wrap {
    padding: 0 32px;
}

.ant-tabs.ant-tabs-top {
    position: relative;
    top: -44px;
    z-index: 99;
}

.profilePageTab .topNavBar {
    padding-bottom: 2.5rem;
}

.profilePageTab {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ant-tabs-tabpane,
.ant-tabs-content {
    height: 100%;
}

.profilePageTab .scrollViewMain {
    height: 100%;
    max-height: 100%;
    padding-top: 12px;
}

.profilePage {
    height: 100%;
    background: #fff;
    padding: 24px;
    border-radius: 6px;
}

#root {
    overflow: auto;
}

.conributionPageChart {
    height: 100%;
}

.MenuActionList .ant-dropdown-menu-item.EditDeleteTable:last-child {
    border: 0;
}

.MenuActionList .ant-dropdown-menu-item.EditDeleteTable {
    padding: 12px;
}

.TableDropDown {
    padding: 16px;
    width: 210px;
    height: auto;
    top: 0px;
    left: 0;
    z-index: 9999;
}

.actionTable {
    position: relative;
    z-index: 999;
}

.IconsTable {
    width: 22px;
    height: 22px;
    display: inline-block;
    background: url(../images/EditIcon.svg) no-repeat center center;
    background-size: 18px;
    margin-right: 14px;
}

.DeleteIcon {
    background-image: url(../images/DeleteIcon.svg);
    background-size: 22px;
}

.EditDeleteTable {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(161, 164, 171, 0.2);
}

.MenuActionList .ant-menu-item {
    color: #000;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.actionBtnTable {
    background-color: #f9f9f9;
    width: 32px;
    box-shadow: 0px 0px 2px 1px rgba(105, 112, 119, 0.08);
    border-radius: 6px;
    height: 32px;
    border: 0;
    cursor: pointer;
}

.defaultForm label {
    width: 100%;
}

.defaultForm .ant-col {
    width: 100%;
}

.defaultForm .ant-form-item {
    display: block;
}

.defaultForm .dOBSelects .ant-col {
    padding: 0 0 0 15px !important;
}

.contributionChart .donutChartCenter {
    min-height: 333px;
}

.donutChartExp .card-title {
    white-space: nowrap;
}

.proContentRow {
    display: flex;
    justify-content: space-between;
}

.profileSection .proContentRow {
    border-bottom: 0.7px solid rgba(224, 224, 224, 0.68);
    padding: 10px 0;
}

.profileRightCont {
    color: #1b2531;
    font-weight: 600;
}

.profileSection h2 {
    border-bottom: 0.7px solid rgba(224, 224, 224, 0.68);
    margin: 0;
    padding: 0 0 10px;
}

.blueLink {
    color: #37c597 !important;
}

.StatmentLists {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PDFDownloadBtn {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: rgba(45, 59, 85, 0.8);
    display: flex;
    align-items: center;
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
    background: none !important;
}

.PDFIcon {
    background: url(../images/PDFIcon.svg) no-repeat center center;
    background-size: 24px;
    margin-left: 8px;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
}

.StatmentLists .PDFDownloadBtn {
    visibility: hidden;
    opacity: 0;
}

.StatmentLists:hover .PDFDownloadBtn {
    visibility: visible;
    opacity: 1;
}

.DocumentPage .PDFDownloadBtn {
    float: right;
}

.DocumentPage .customPagination {
    width: 100%;
    text-align: center;
}

.profileLeftCont p {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #49505a;
}

.profileLeftCont strong {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #49505a;
}

.SwitchDefault {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #686c77;
}

.MessageCenterSlider {
    width: 100%;
    float: left;
    height: 100%;
    overflow: hidden;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
    top: auto;
    bottom: 0;
    background: red;
}

.DocumentPage .PDFDownloadBtn {
    visibility: hidden;
}

.DocumentPage tr.ant-table-row:hover .PDFDownloadBtn {
    visibility: visible;
}

.AutoCompleteSelectCont {
    width: 50%;
    float: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.AutoCompleteSelectCont .SelectBoxTopBar {
    float: right;
    max-width: 140px;
    margin-left: 8px;
}

.AutoCompleteSelectCont .AutoSearch {
    max-width: 290px;
}

.AutoCompleteSelectCont .ant-select.DefaultSelect {
    padding: 2px;
}

.NoFullHeight .LeftSidebar {
    height: auto;
}

.ConfirmationTop {
    align-items: flex-start;
}

.DocumentPage .ant-table-thead {
    display: none !important;
}

.LoaderOuter {
    position: relative;
    width: 100%;
}

.LoaderLottie {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background: rgba(255, 255, 255, 0.9);
}

.LoaderLottieCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -100px;
}

.autoCompleteSelectCont .autoSearch {
    padding-top: 0;
    padding-bottom: 0;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 12px;
}

.autoCompleteSelectCont .ant-select.defaultSelect {
    padding: 4px;
}

.ant-tabs-tabpane,
.ant-tabs-content {
    padding-left: 15px;
    padding-right: 15px;
}

.topNavBar {
    padding-bottom: 2.18rem;
}

.taxPDFDownload .PDFDownloadBtn {
    display: none;
    float: right;
}

.taxPDFDownload .profileLeftCont:hover .PDFDownloadBtn {
    display: block;
}

.ProContentRow {
    padding-top: 0;
    padding-bottom: 0;
}

.profileLeftCont {
    cursor: pointer;
    padding-top: 18px;
    padding-bottom: 16px;
}

.ant-pagination-options-size-changer.ant-select {
    padding: 4px;
}

.ant-form-item-control {
    position: relative;
}

.confirmPassword {
    position: relative;
    margin-bottom: 50px;
}

.passConError {
    position: absolute;
    width: 100%;
    bottom: -40px;
}

.fullTimeStudent {
    width: 100%;
    display: flex;
}

.defaultForm .fullTimeStudent label {
    width: auto;
}

.dependentsTable .ant-table-tbody>tr>td:nth-child(6) {
    text-transform: capitalize;
}

// .ant-table-tbody > tr > td { font-size: 13px;}
.TokenBox {
    text-align: center;
    margin: 0 auto;
}

.BankingHeader {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #2d3b55;
    padding: 0;
    margin: 0 0 15px;
}

.BankingTab * {
    text-align: left;
}

.BankingTab h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #2d3b55;
    text-align: left;
    margin: 0 0 10px;
}

.BankingTab p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #2d3b55;
    text-align: left;
    margin: 0 0 16px;
}

.AddedBank {
    background: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #e1e2e4;
}

.GreyButton,
button.GreyButton {
    color: #686c77;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #686c77;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #686c77;
    height: 40px;
    line-height: 100%;
    padding: 10px 20px;
    border: 2px solid #a1a4ab;
    outline: none;
    border-radius: 4px;
    background: none !important;
}

.AddedBank {
    display: flex;
    justify-content: space-between;
    padding: 18px;
    margin-bottom: 16px;
    align-items: center;
}

.BeneficiariesTab {
    min-height: 470px;
}

.buttonMain.ButtonBorderGreen,
.buttonMain.ButtonBorderRed {
    opacity: 0.32;
}

.buttonMain.ButtonBorderGreen:hover,
.buttonMain.ButtonBorderRed:hover {
    opacity: 1;
}

.StatmentLists {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonBorder,
button.buttonBorder {
    padding: 12px 24px;
    display: inline-block;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    align-items: center;
    color: #37c597;
    border: 2px solid #37c597 !important;
    outline: none !important;
    box-shadow: none !important;
    background: none !important;
}

.buttonBorder,
.buttonBorder:hover {
    border: 2px solid #37c597;
    border-radius: 4px;
}

.BankingTab {
    padding: 24px;
    height: 100%;
    border-radius: 6px;
    min-height: calc(100vh - 130px);
}

.SwitchDefault {
    margin: 30px 0 0;
    display: block;
}

.ProfileRightMenu {
    width: 100%;
    float: left;
    margin: 0;
    padding: 0 16px;
    list-style: none;
    border-left: 1px solid #898e94;
}

.ProfileRightMenu li {
    width: 100%;
    display: block;
}

.ProfileRightMenu li a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #2d3b55;
    text-align: left;
    padding: 16px 0;
    display: block;
}

.ProfileRightMenu {
    position: fixed;
}

.ProfileRightMenu li a:not([href]):not([class]),
.ProfileRightMenu li a:not([href]) {
    color: #898e94;
}

.ProfileRightMenu li a:not([href]).activeNew {
    color: #2d3b55;
}

.prefrenceLeft {
    background: #fff;
    padding: 24px;
    border-radius: 6px;
}

.profilePageTab {
    display: block;
}

.LoaderSmall {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background: rgba(255, 255, 255, 0.9);
}

.ant-card-body,
.WhiteCardContent,
.LoaderOuter {
    height: 100%;
}

.heightFull {
    height: 100%;
}

.overflowHidden {
    overflow: hidden;
}

.filterContribution .ant-select.defaultSelect {
    width: auto;
}

.filterContribution {
    width: 100%;
    text-align: right;
    display: inline-block;
    margin: 0 0 16px;
}

.donutChartCenter>div {
    max-height: 256px;
}

.donutChartCenter .apexcharts-canvas {
    max-height: 256px;
}

.defaultForm .ant-btn-primary,
.defaultForm .ant-btn {
    width: auto;
}

.ant-spin-dot-item,
.ant-spin {
    color: #37c597;
    line-height: 100%;
}

.ant-spin-dot-item {
    background-color: #37c597;
}

.ant-spin-spinning {
    margin: 0 auto;
}

.spinnerCont {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
}

.heightAuto {
    height: auto;
    min-height: auto;
}
.height100 {
    height: 100%;
}
.ant-picker-today-btn {
    color: #37c597;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #37c597;
}

.ant-picker {
    width: 100%;
    padding: 0;
}

.ant-picker-input {
    position: relative;
}

.ant-picker-suffix {
    position: absolute;
    right: 10px;
}

.profileRightCont {
    padding-top: 18px;
    padding-bottom: 16px;
}

.dOBSelects {
    margin-bottom: 0;
}

.sicon2 {
    background-image: url(../images/sponser002.svg);
}

.sicon3 {
    background-image: url(../images/sponser003.svg);
}

.sicon4 {
    background-image: url(../images/sponser004.svg);
}

.sponsorTopCards .ant-card-body {
    padding: 24px;
}

.sponsorTopCards p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #49505a;
    margin: 0;
}

.sponsorTopCards h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    color: #2d3b55;
    margin-top: 10px;
}

.sponsorTopCards .sponsorBottomTop {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #2d3b55;
    border-top: 1px solid #eff0f2;
    padding: 10px 0 0;
}

.sponsorTopCards .sponsorBottomTop span {
    color: #37c597;
}

.sponsorCardsDash {
    min-height: 400px;
}

.sponsorCardsDashBottom {
    min-height: 300px;
}

div#feedback\ error {
    color: red;
}

.error {
    color: red;
}

input.ant-input.ant-input-lg.inputIconsDollar {
    background: #ffffff;
    border: 1px solid #cecece;
    box-sizing: border-box;
    border-radius: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    padding: 4px 41px;
    width: 100%;
}

.yellowCard h2 {
    color: #fe9a20;
}

.greenCard h2 {
    color: #37c597;
}

.btnGrey,
button.btnGrey {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: #898e94;
    border: 1px solid #898e94;
    border-radius: 4px;
    padding: 4px 16px;
    background: none;
    margin-bottom: 10px;
}

.alertData {
    width: 100%;
    display: block;
}

.alertData .alertDataRow {
    display: flex;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 48px;
    color: #2d3b55;
}

.alertDate {
    width: 20%;
}

.participantChart .apexcharts-menu-icon {
    display: none !important;
}

.partiCheckbox {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 8px;
    border: 1px solid #37c597;
    border-radius: 2px;
    background: url(../images/checkGrey.svg) no-repeat center center rgba(55, 197, 151, 0.32);
    background-size: 14px;
    vertical-align: middle;
}

.checkboxText {
    display: flex;
    align-items: center;
    margin-left: 22px;
}

.yellowCheckbox {
    background-color: rgba(255, 185, 41, 0.32);
    border-color: #fe9a20;
}

.greyCheckbox {
    background-color: rgba(45, 59, 85, 0.32);
    border-color: #2d3b55;
}

.pendingStatus {
    border: 1px solid #fe9a20;
    box-sizing: border-box;
    border-radius: 40px;
    background: #fff8e5;
    color: #2d3b55;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    padding: 2px 10px;
    display: inline-block;
}

.partiRightSidebar {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 99;
    left: 0;
    top: 0;
}

.rightPartWhiteBg {
    width: 500px;
    padding: 60px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 9999;
}

.partiBgGrey {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    background: #2d3b55;
    opacity: 0.4;
}

.arrowsLeftRightPart {
    width: 32px;
    height: 32px;
    display: inline-block;
    margin-right: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background: #ffffff;
    background: #ffffff;
}

.leftArrowPart {
    background-image: url(../images/arrowGreyleft.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 6.38px;
}

.rightArrowPart {
    background-image: url(../images/arrowGreyRight.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 6.38px;
}

.rightPartWhiteBg h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #2d3b55;
}

.statusUpdate {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #2d3b55;
    margin: 0 0 10px;
}

.reminderButton,
button.reminderButton {
    background: #ffffff;
    border: 1px solid #898e94 !important;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    padding: 8px 16px;
}

.accordianHeading {
    background: #f9f9f9;
    font-style: normal;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #2d3b55;
    padding: 10px;
    align-items: center;
}

.arrowAcc {
    background: url(../images/arrowDownNew.svg) no-repeat center center;
    display: inline-block;
    width: 14px;
    height: 8px;
    background-size: 14px;
}

.accordianRows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #686c77;
    padding: 10px 0;
    border-bottom: 0.7px solid #e0e0e0;
}

.accordianContent {
    padding: 0 10px;
    margin-bottom: 8px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    left: auto;
    right: 10px;
    position: absolute;
}

.ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    background: #f9f9f9;
    font-style: normal;
    display: flex;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #2d3b55;
    padding: 10px;
    align-items: center;
}

.ant-collapse>.ant-collapse-item {
    margin-bottom: 10px;
}

.ant-collapse {
    background: none;
    border: 0;
}

.ant-collapse>.ant-collapse-item {
    border-bottom: 0px solid #e0e0e0;
}

.selectMidium .selectBoxTopBar {
    max-width: 200px;
}

.eligibleIcon {
    display: block;
    text-align: center;
}

.implimentBtn {
    background: #2d3b55;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    padding: 10px 23px;
    margin: 5px 0 10px;
    display: inline-block;
}

.eligibleCard h2 {
    margin: 0 0 20px;
}

.eligibleCard {
    text-align: center;
}

.greenImplimentBg {
    background: url(../images/greenImplimentBg.svg) no-repeat center center #37c597 !important;
    background-size: cover;
}

.investmentPercentCont .selectBoxTopBar {
    width: auto;
    display: inline-block;
    margin-left: 10px;
}

.arrowUp {
    width: 20px;
    height: 20px;
    background: url(../images/arrowUp.svg) no-repeat center center;
    background-size: 20px;
    display: inline-block;
}

.percentageInv {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #37c597;
}

.investmentPercentCont {
    display: flex;
    align-items: center;
}

.InvestmentChart .apexcharts-toolbar,
.InvestmentChart .apexcharts-yaxis {
    display: none;
}

.participantDetails .profileLeftCont,
.participantDetails .profileRightCont {
    padding: 0;
}

.participantDetails .profileSection .proContentRow {
    padding: 12px 0 13px;
}

.partHeading {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #2d3b55;
    display: flex;
    align-items: center;
    border-bottom: 0.7px solid #e0e0e0;
    padding: 16px 0;
    margin: 0 0 16px;
}

.participantDetails .profileSection h2 {
    font-size: 14px;
}

.participantDetails .profilePage {
    padding: 0;
}

.backBtnPart {
    background: #ffffff;
    border-radius: 4px;
    padding: 10px 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #686c77 !important;
    align-items: center;
}

.backArrowPart {
    background: url(../images/arrowBack.svg) no-repeat center center;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: 16px;
    margin-right: 8px;
    vertical-align: middle;
}

.participantChartDonut {
    display: flex;
    align-items: center;
    width: 100%;
}

.rightPartChartDetails {
    width: 40%;
}

.ContChartLabels {
    background: #37c597;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.ContChartLabels.yellow {
    background: #ffb929;
}

.SelectPartiDetails {
    width: 100%;
    justify-content: space-between;
}

.SelectPartiDetails .partHeading {
    border: 0;
}

.SelectPartiDetails {
    border-bottom: 0.7px solid #e0e0e0;
}

.participantChart {
    margin-bottom: 16px;
}

.ParticipantDetailTable .ant-pagination {
    display: none;
}

.pendingStatus.Active {
    border: 1px solid #37c597;
    background: #ebfcf6;
}

button.SendRepinderActive {
    background: #37c597;
    border-radius: 4px;
    color: #fff;
    border: 0 !important;
}

.AccordianNobg {
    background: none;
    border-bottom: 0.7px solid #e0e0e0;
    padding: 10px 0;
}

.partiDetailRightBar .accordianContent {
    padding: 0;
}

.DocumentsSponsorPage .autoCompleteSelectCont {
    justify-content: left;
    float: left;
}

.documentRightSidebar p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #898e94;
}

.noBorder {
    border: 0;
    margin: 0 0 10px;
}

.MuiDialog-container .MuiTypography-h6 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #2d3b55;
}

.MuiDialog-container .MuiTypography-body2 {
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 24px;
    color: #2d3b55;
}

.MuiDialog-container .makeStyles-logout-3 {
    border: 2px solid #686c77 !important;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 4px;
    background: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #686c77;
    padding: 8px;
}

.MuiDialog-container .MuiButton-containedPrimary {
    background: #37c597 !important;
    border: 1px solid #37c597;
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 8px;
}

.MuiDialog-container .makeStyles-countdown-4 {
    color: #2d3b55;
}

.ant-input-group .ant-input {
    background: #ffffff;
    padding: 9px 11px;
    border-radius: 4px;
}

.searchDropDown .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    box-shadow: none;
    border-left: 0;
    border-radius: 0 4px 4px 0;
    height: 42px;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
   // box-shadow: none;
   // border-color: #cecece;
    border-right: 0;
}

.autoCompleteSelectCont .autoSearch {
    max-width: 390px;
}

.blogBanner {
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
}

.blogBanner img {
    // opacity: 0;
    // visibility: hidden;
    opacity: 1;
    visibility: visible;
    width: 100%;
    height: 100%;
}

.forYouPosts h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #2d3b55;
}

.forYouPosts p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #898e94;
}

.forYouHeadingPost {
    display: flex;
    justify-content: space-between;
}

.ForDots {
    background: url(../images/foryouDots.svg) no-repeat center center #f9f9f9;
    width: 48px;
    height: 48px;
    display: inline-block;
    background-size: 20px;
    border-radius: 100%;
    float: right;
}

.show-details {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 22px;
    border-radius: 100%;
    justify-content: center;
    background: #eff0f2;
    &:hover {
        color: gray;
    }
}

.forYouPosts {
    margin: 0 0 24px;
}

.forYouRightCards {
    margin: 0 0 24px;
}

.forYouHeadView {
    display: flex;
    justify-content: space-between;
}

.forYouRightheading h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #2d3b55;
}

.forYouHeadView a {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 1;
    color: #2d3b55;
}

.forYouRightheading p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #686c77;
    max-width: 80%;
}

.forYouRtTabs {
    display: flex;
    align-items: center;
    position: relative;
    border-top: 0.7px solid #e0e0e0;
    padding: 11px 0;
}

.ForIconBgRt {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 12px;
    background: url(../images/iconForYouRt01.svg) no-repeat center center #fe9a20;
    background-size: 24px;
}

.arrowForRight {
    background: url(../images/ArrowRightFor.svg) no-repeat center center;
    width: 8px;
    height: 13px;
    display: inline-block;
    float: right;
    position: absolute;
    right: 0;
}

.forYouRtTabs h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #49505a;
}

.forIcon2 {
    background-image: url(../images/iconForYouRt02.svg);
    background-color: #fc636c;
}

.forIcon3 {
    background-image: url(../images/iconForYouRt03.svg);
    background-color: #504da8;
}

.forIcon4 {
    background-image: url(../images/iconForYouRt04.svg);
    background-color: #504da8;
}

.forIcon5 {
    background-image: url(../images/iconForYouRt05.svg);
    background-color: #fc636c;
}

.forIcon6 {
    background-image: url(../images/iconForYouRt06.svg);
    background-color: #504da8;
}

.forIcon7 {
    background-image: url(../images/iconForYouRt07.svg);
    background-color: #fe9a20;
}

.MuiDialog-container .MuiDialogActions-root {
    padding: 17px 24px;
}

.ForYouRightSidebar .rightPartWhiteBg {
    width: 60%;
    padding: 32px;
}

.imgForYouSidebarBanner {
    width: 100%;
    height: 30vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
    margin: 0 0 24px;
}

.ForYouRightSidebar h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #2d3b55;
}

.ForYouRightSidebar h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #2d3b55;
    margin: 10px 0;
}

.ForYouRightSideba p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #49505a;
    margin: 0 0 15px;
}

.headingIcon {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: inline-block;
    margin-right: 12px;
    background: url(../images/iconForYouRt01.svg) no-repeat center center #fe9a20;
    background-size: 24px;
}

.iconNameTip {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #2d3b55;
}

.ForYouRightSidebar h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #2d3b55;
}

.ForYouRightSidebar p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #686c77;
}

.ForYouSideRightSidebarFooter {
    text-align: right;
}

.ForYouSideRightSidebarFooter button {
    background: #f9f9f9;
    border-radius: 6px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-left: 24px;
    padding: 11px 50px;
    border: 0 !important;
}

.iconFootRtFY {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    background: url(../images/copyRtForyou.svg) no-repeat center center;
    background-size: 18px;
}

.iconShareFU {
    background-image: url(../images/uploadRtForYou.svg);
}

.ForYouRightSidebar .ant-card-bordered {
    border: 0;
    border-bottom: 1px solid #eff0f2;
    border-radius: 0;
    margin-bottom: 16px;
}

.ForYouRightSidebar .ant-card-body {
    padding: 0 22px;
}

.headingIcon.icon2 {
    background-image: url(../images/iconForYouRt02.svg);
    background-color: #fc636c;
}

.headingIcon.icon3 {
    background-image: url(../images/iconForYouRt03.svg);
    background-color: #504da8;
}

.sidebarHeaderClose {
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #2d3b55;
    margin-bottom: 38px;
}

.closeBtnForYouSD {
    background: url(../images/closeSidebar.svg) no-repeat center center;
    background-size: 14px;
}

.expImagePreview {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 600px;
    padding: 0 0 100px;
    width: 100%;
    display: flex;
    position: absolute;
    top: 100px;
    left: 0;
    // ===
    border-color: #f9f9f9;
}
.closeExpenseImg{
  position: absolute;
  top: 85px;
  right: 0;
  z-index: 100;
}

.uploadDocuments {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.visually-hidden {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    visibility: visible;
    opacity: 0;
    z-index: 99;
    cursor: pointer;
}

.uploadIconText .uploadLargeIcon {
    background: url(../images/upload2.png) no-repeat center center;
    background-size: contain;
    display: inline-block;
    width: 96px;
    height: 83px;
}

.uploadIconText p {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    color: #37c597;
    text-align: center;
}

.uploadIconText {
    text-align: center;
}

.uploadDocuments {
    background: #f9f9f9;
    border-radius: 6px;
}
.position-relative
{
    position: relative;
}
.upload-error
{
    position: absolute;
    top: 120px;
    bottom: calc(100% - 145px);
    width: 208px;
    left: -200px;
}
.ExpensesPage h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #2d3b55;
}

.inputRows lable {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #686c77;
}
.inputRows small{color: red;}
.ant-input,
.ant-picker-input input {
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid rgba(134, 141, 149, 0.24);
    height: 38px;
    padding: 10px;
}

.ant-picker {
    border: 0;
}

.ant-select {
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid rgba(134, 141, 149, 0.24);
    height: 40px;
    padding: 10px;
}

.inputRows {
    margin-bottom: 12px;
}

.inputRows em {
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #a1a4ab;
}

.remubuseBtns .buttonMain {
    text-align: center;
    width: auto;
    display: inline-block;
}

.remubuseBtns .noBgborder {
    border: 2px solid #37c597;
    background: #fff;
    color: #37c597;
}

.remubuseBtns {
    display: flex;
    justify-content: space-between;
    margin: 24px 0 0;
}

.reimTypeCont {
    background: #f9f9f9;
    position: relative;
    border-radius: 6px;
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
}

.reimTypeCont h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: #2d3b55;
}

.reimTypeMiddle {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
}

.reimTypeValue {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 150%;
    color: #2d3b55;
}

.leftRebCont {
    padding: 42px;
}

.reimTypeMiddle {
    padding: 0;
}

.leftRebCont h3 {
    margin: 40px 0 90%;
}

.rightRebCont .buttonMain,
.leftRebCont .buttonMain {
    position: absolute;
    top: -20px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    padding: 5px 31px;
}

.rightRebCont h3 {
    margin: 36px 0 30px;
}

.reimPlansRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid #e1e2e4;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 20px;
    margin: 0 0 12px;
    cursor: pointer;
}

.plansReimType {
    width: 100%;
}

.rightRebCont {
    padding: 36px;
}

.reimPlansRow h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    color: #2d3b55;
}

.leftReimPlan {
    text-align: left;
}

.reimPlansRow p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    color: #2d3b55;
    margin: 0;
}

.rightReimPlan {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: #2d3b55;
}

.subjectToReim {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
    margin: 20px 0 0;
}

.reimPlansRow.active {
    border: 3px solid #37c597;
}

.remubuseBtnsPlans {
    margin: 70px 0 0;
}

.leftSidebar li {
    position: relative;
}

.mainMenu li a.menuHideShow {
    position: absolute;
    top: 50%;
    right: -11px;
    width: 40px;
    margin-top: -34px;
    font-size: 22px;
    color: #37c597;
    background: none !important;
}

.leftSidebar.Collapsed .logo img {
    width: 30px;
}

.leftSidebar.Collapsed a {
    font-size: 0;
    padding: 18px 16px 18px 16px;
}

.leftSidebar.Collapsed {
    width: 60px;
}

.leftSidebar.Collapsed li.logo div {
    padding: 12px 10px;
}

.leftSidebar.Collapsed .nameIDLeft {
    display: none;
}

.leftSidebar.Collapsed .profileIcon {
    margin: 20px 0px 20px 5px;
    margin-right: 0px;
}

.leftSidebar.Collapsed .sidebarMenuDrop {
    left: 60px;
}

.leftSidebar.Collapsed .sidebarMenuDrop a {
    font-size: 14px;
}

.searchDropDown .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    // height: 40px;
    height: 38px;
}

.sidebarClose {
    position: absolute;
    width: 30px;
    height: 30px;
    background: url(../images/closeSidebar.svg) no-repeat center center;
    background-size: 14px;
    right: 15px;
    top: 15px;
}

.participantChart {
    margin: 0 0 16px;
}

.eyeIcon {
    background: url(../images/ShowPassword.svg) no-repeat;
    width: 20px;
    cursor: pointer;
    height: 20px;
    background-size: 16px;
    display: inline-block;
    margin-right: 8px;
    position: relative;
    bottom: -5px;
}
.EditSettingForm .eyeIcon {width: 30px;
    position: absolute;
    right: -10px;
    top: 13px;}
.rightPartWhiteBg {
    overflow-y: auto;
}
.company-upload .ant-btn{padding: 0 15px; font-size: 14px;height: 40px;}
.inputIconsGroup {
    position: relative;
}

.inputIcons.Dollar {
    background: url(../images/dallarIcon.svg) center center no-repeat rgba(134, 141, 149, 0.24);
    background-size: 12px;
    height: 38px;
    width: 40px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
}

.partiCheckbox {
    border-radius: 100%;
    width: 12px;
    height: 12px;
    margin-right: 8px;
    background: rgba(55, 197, 151, 0.32);
}

.partiCheckbox.yellowCheckbox {
    background: rgba(255, 185, 41, 0.32);
}

.partiCheckbox.greyCheckbox {
    background: rgba(45, 59, 85, 0.32);
}

// .blueCardTop {background-color: #37C597; }
.leftSidebar li.logo img {
    max-width: 85%;
}

.leftSidebar:not(.Collapsed) .mainMenu li a.menuHideShow {
    right: 13px;
}

.MuiDialog-container .makeStyles-logout-3 {
    background: #fff !important;
}

.leftSidebar.Collapsed .mainMenu li a.menuHideShow {
    right: 1px;
    width: 20px;
    margin-top: -33px;
    font-size: 17px;
}

.MuiDialog-container .makeStyles-countdown-4 *,
.MuiDialog-container .makeStyles-logout-3 * {
    color: #2d3b55 !important;
}

.sponsorSelectParticipant {
    width: 110px;
    float: right;
}

.eyeIcon.active {
    background-image: url(../images/HideEyeIcon.svg);
}

.investmoreModal{width: 800px !important;}
.investmoreModal .manageCardsSliderButton p{font-size: 12px;}
.investmoreModal .stepTabsFive { min-height: 380px;}
.investmoreModal .buttonsManageBottom {bottom: 35px;}
.investmoreModal .ant-modal-body{padding: 0 24px;}
.investmoreModal .ant-modal-header{display: flex; justify-content: center; margin-bottom: 20px;}
.investmorebtn {
  display: none;
  position: absolute;
  top: -40px;
  right: 0;
}
.dashboardinvestmore{display: none; margin-right: 10px;}
// .ant-table-body {    max-height: calc(100vh - 300px) !important;}
.ExpensesPage .buttonMain,
.ExpensesPage .buttonMain:hover {
    border: 2px solid #37c597 !important;
    background: #fff !important;
    color: #37c597 !important;
    padding: 6px 8px !important;
    line-height: 100% !important;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 500;
    line-height: 1.75 !important;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

.ForIconBgRt {
    min-width: 40px;
}

.ant-select {
    padding: 3px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-size: 14px;
    font-weight: normal;
}

.remubuseBtnsPlans {
    margin: 90px 0 0;
}

.ant-notification-notice.ant-notification-notice-success .ant-notification-close-x * {
    color: #2d3b55;
}

.settingForm h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2d3b55;
    border-bottom: 0.7px solid rgba(224, 224, 224, 0.68);
    margin: 22px 0 15px;
    padding: 0 0 10px;
}

.settingPage {
    width: 100%;
    float: left;
    height: 100%;
}

.settingPageScroll {
    max-height: calc(100vh - 135px);
}

.settingPageScroll.scrollTabs {
    max-height: calc(100vh - 166px);
}

.settingPage .remubuseBtns .noBgborder {
    line-height: 100%;
    float: right;
    border: 2px solid #37c597 !important;
}

.settingPage .remubuseBtns {
    display: block;
}

.breakForm {
    margin: 20px 0 0;
    display: block;
}

.ant-select-item-option-content {
    font-size: 14px;
    font-weight: normal;
    width: 100%;
    padding: 0 11px;
    font-style: normal;
    line-height: 20px;
    color: #49505a;
}

.PhoneInputCountryIcon {
    background: url(../images/callIcon.svg) no-repeat center center;
    height: 21px !important;
}

.PhoneInputCountryIcon svg {
    opacity: 0;
}

.defaultForm .ant-input,
.defaultForm input {
    height: 40px;
}
.defaultForm .ant-input-affix-wrapper-has-feedback input{border: 0 !important;}
.settingForm .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    background-position: right 0px;
    background: none;
}


.login-inner input#password, .login-inner input#confirm_password, .ant-form-item.username .ant-input-affix-wrapper, .ant-form-item.email .ant-input-affix-wrapper {border: 0;}
.defaultForm .ant-input-affix-wrapper, .create-account-div .ant-input-affix-wrapper{
  padding-top: 0;
  padding-bottom: 0;
}
.create-account-div .ant-form-item.email .ant-input-affix-wrapper{padding: 0}
.ant-form-item.username #username, .ant-form-item.email #email{padding: 0 11px;}
.ant-form-item.username .ant-input-affix-wrapper{padding: 0;}
.settingAdminScroll .ant-table-body {
    height: calc(100vh - 410px) !important;
    overflow: hidden;
    overflow-y: scroll;
}

.settingForm .inputRows {
    display: flex;
    justify-content: space-between;
}

.EditSettingForm input {
    // max-width: 50%;
    max-width: 300px;
    height: 40px;
    min-width: 300px;
}

//  .flag.us {
//   display: none !important;
// }
.editSettingDisable .react-tel-input input {
    border: 0;
    text-align: right;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: #1b2531;
    font-weight: 600;
}

.special-label {
    display: none;
}

input.form-control {
    max-width: 100%;
}

.EditSettingForm .ant-select {
    // max-width: 50%;
    max-width: 300px;
}

.EditSettingForm .PhoneInput input {
    // max-width: 100%;
    max-width: 300px;
}

.editCompInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 21px;
}
.editCompInfo .ant-upload .ant-btn{font-size: 14px !important;height: 40px;
    display: flex;
    align-items: center;}
.editSettingDisable .inputRows .ant-input,
.editSettingDisable .inputRows .ant-select,
.editSettingDisable .react-tel-input {
    border: 0;
    text-align: right;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: #1b2531;
    font-weight: 600;
}

.editSettingDisable .ant-select-selection-item {
    border: 0;
    text-align: right;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: #1b2531;
    font-weight: 600;
    padding: 0 !important;
}

.editSettingDisable .ant-select-arrow {
    display: none;
}

.editSettingDisable .inputRows {
    border-bottom: 0.7px solid rgba(224, 224, 224, 0.68);
    padding: 0 0 10px;
}

.settingForm h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #2d3b55;
    margin-bottom: 21px;
}

.settingForm h2 {
    border-bottom: 0.7px solid rgba(224, 224, 224, 0.68);
    padding-bottom: 10px;
}

.settingForm .editCompInfo h2 {
    border: 0;
}

.editCompInfo {
    border-bottom: 0.7px solid rgba(224, 224, 224, 0.68);
    padding-bottom: 10px;
}

.editSettingDisable .PhoneInput input {
    border: 0;
    text-align: right;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: #1b2531;
    font-weight: 600;
}

.editSettingDisable .PhoneInputCountryIcon,
.editSettingDisable .PhoneInputCountrySelectArrow {
    display: none;
}

.inputRows.remubuseBtns {
    border: 0 !important;
}

.buttonRightUpdate .buttonMain {
    float: right;
}

.settingForm .inputRows.buttonRightUpdate {
    display: block;
}

.AdminDeleteBtns .cancelLeftBtn {
    margin-right: 21px;
}

.settingForm .PhoneInput {
    min-width: 50%;
}

.settingForm:not(.editSettingDisable) .inputRows {
    position: relative;
    // margin-bottom: 30px;
    margin-bottom: 0 0 10px;
}

.settingForm div#feedback\ error {
    margin-bottom: 15px;
    text-align: right;
}

.PhoneInputCountryIcon,
.PhoneInputCountrySelectArrow {
    display: none !important;
}

.PhoneInputCountry {
    display: none !important;
}

.EditSettingForm .ant-select {
    border: 1px solid #cecece;
    border-radius: 6px;
    height: 40px;
}

.editCompInfo .buttonMain,
.editCompInfo .buttonMain:hover {
    line-height: 40px;
    height: 40px;
}

.administratorModal {
    min-width: 700px;
    height: 300px;
}

.administratorModal .ant-modal-content p {
    margin-bottom: 40px;
    line-height: 30px;
}

.IconsTable {
    vertical-align: middle;
}

.EditSettingForm .inputRows lable {
    padding-top: 10px;
}

.ant-select input {
    line-height: 100%;
    padding: 0;
    height: 30px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #2d3b55;
}

.EditSettingForm .inputRows .react-tel-input {
    width: 100%;
    max-width: 50%;
}

.defaultForm .react-tel-input .form-control {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cecece;
    box-sizing: border-box;
    border-radius: 6px;
    font-style: normal;
    font-weight: normal;
    // font-size: 18px;
    line-height: 24px;
    padding: 12px;
    width: 100%;
    color: #2d3b55;
    height: 40px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
}

.flag-dropdown {
    display: none;
}

.editSettingDisable.defaultForm .react-tel-input .form-control {
    border: 0;
    border: 0;
    text-align: right;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: #1b2531;
    font-weight: 600;
}

.addAdminBtns .ant-form-item-control-input-content {
    display: block;
}

.CancelButtonrightadditional {
    margin-right: 21px;
    background: #e73b3b !important;
    border-color: #e73b3b !important;
}

.leftSidebar {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.mainMenu li a {
    white-space: nowrap;
}

.ReportsHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

//  .ant-form-item-explain-error {text-transform: capitalize; }
.searchDropDown .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding-left: 10px;
    line-height: 33px;
}

.leftSelectBoxReport .DefaultSelect {
    width: auto;
    margin-right: 16px;
}

.leftSelectBoxReport .ant-picker {
    width: auto;
}

.datePickerShow {
    margin-right: 16px;
}

.leftSelectBoxReport {
    display: flex;
    font-size: 18px;
    font-weight: normal;
    float: left;
    align-items: center;
    position: relative;
}

.leftSelectBoxReport input {
    font-size: 18px;
}

.datePickerReport span {
    padding: 0 5px;
}

.leftSelectBoxReport .ant-btn {
    height: 40px;
    line-height: 100%;
}

.ant-picker-input>input:focus,
.ant-picker-input>input-focused,
.ant-picker-input>input:hover {
    border-radius: 4px;
    border: 1px solid rgba(134, 141, 149, 0.24);
    box-shadow: none;
}

.ReportsSearch .autoCompleteSelectCont {
    max-width: 390px;
}

.ant-picker-focused {
    box-shadow: none;
}

.defaultForm .CancelButtonrightadditional {
    border: 2px solid #37c597 !important;
    background: #fff !important;
    color: #37c597 !important;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-checked::after {
    border-color: #37c597;
}

.ant-radio-inner::after {
    background-color: #37c597;
}

.messageBoxReport {
    position: absolute;
    bottom: -27px;
    font-size: 16px;
    border: 0;
    padding: 4px;
    border-radius: 3px;
    white-space: nowrap;
    line-height: 100%;
    color: #37c597;
    font-weight: 600;
}

.settingForm h2.fontNormal {
    font-weight: normal;
}

.leftSidebar li.logo {
    height: 74px;
}

.leftSidebar.Collapsed li.logo div {
    padding: 22px 10px;
}

.reportsReadMore {
    background: url(../images/moreIcon.svg) no-repeat center center;
    background-size: 24px;
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-left: 5px;
    border: 1px solid #b1b7ba;
    box-shadow: 0px 0px 2px 1px rgba(105, 112, 119, 0.08);
    border-radius: 4px;
    vertical-align: middle;
}

.downloadBtnPdf {
    height: auto;
}

.downloadBtnPdf.disable {
    opacity: 0.4;
}

.greenSelect {
    border: 1px solid #37c597;
}

.greenSelect.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #37c597;
}

.greenSelect .ant-select-arrow {
   // background: url(../images/arrowDownGreen.svg) center center no-repeat;
    background-size: 12px;
}

.ant-picker-input input {
    border: 0 !important;
}

.ant-picker.ant-picker-range {
    border: 1px solid rgba(134, 141, 149, 0.24);
}

.ant-picker-range .ant-picker-active-bar {
    margin-left: 0;
    background: #37c597;
}

.ant-table-thead th.ant-table-column-sort {
    background: none;
}

.buttonMain.downloadBtnPdf {
    padding: 0 19px;
    font-weight: normal;
}

.formatSelect {
    width: 120px;
    min-width: 120px;
}

.greenSelect .ant-select-item-option-content:hover {
    color: #fff;
}

.ant-select-item-option-content {
    text-overflow: inherit;
}

.leftSelectBoxReport .DefaultSelect {
    min-width: 130px;
}

.leftSelectBoxReport .DefaultSelect.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #898e94;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.leftSelectBoxReport .DefaultSelect {
    border: 0;
}

.autoCompleteSelectCont .autoSearch,
.autoCompleteSelectCont {
    border: 0;
}

.fontNormal {
    font-weight: normal;
}

// .ant-table-tbody > tr > td {background: #fafafa;}
.ant-table-thead>tr>th,
.ant-table-column-sorters-with-tooltip {
    background: #fafafa;
}

.ant-table-thead th.ant-table-column-sort {
    background: #fafafa;
}

.leftSelectBoxReport input {
    font-size: 14px;
}

.buttonMain.downloadBtnPdf {
    background: #37c597 !important;
    color: #ffffff !important;
}

.menuIconCollapse {
    width: 32px;
    height: 32px;
    display: inline-block;
    background: url(../images/menuArrowRight.png) no-repeat center center;
    background-size: 32px;
}

.menuIconCollapse.menuRight {
    background-image: url(../images/menuArrowLeft.png);
}

.leftSidebar:not(.Collapsed) .mainMenu li a.menuHideShow {
    right: -4px;
}

.iconLetBar {
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
}

.PendingPartDashTable .ant-table-body {
    height: 36vh;
    max-height: 310px !important;
}

.leftSidebar li.logo img {
    min-width: 136px;
}

.leftSidebar.Collapsed li.logo div img {
    width: 30px;
    max-width: 30px;
    min-width: 30px;
}

.sponsorTopCards .sponsorBottomTop {
    font-size: 14px;
}

.pendingParticipant {
    display: flex;
    align-items: center;
}

.pendingParticipant h5 {
    margin-right: 20px;
}

.sponsorCardsDash {
    min-height: 0;
}

.sponsorCardsDash .participantChart {
    margin-bottom: 0;
}

.sponsorParticipantChart .ant-card-body {
    padding-bottom: 0;
}

.sponsorTopCards .ant-card {
    height: 100%;
}

.leftSidebar.Collapsed li.logo div img {
    width: 40px;
    max-width: 40px;
    min-width: 40px;
    max-height: 34.73px;
}

.ant-notification {
    box-shadow: none;
}

.ant-notification-notice-error {
    margin: 0;
    box-shadow: none;
}

.dashboardDocumentTable .ant-table-body {
    height: 35vh;
    max-height: 310px !important;
}

.dashboardDocumentTable .ant-pagination {
    display: none;
}

.ParticipantTable .ant-table-body {
    max-height: calc(100vh - 320px) !important;
}

.leftSidebar li.logo img {
    max-width: 100%;
    width: 152px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #37c597;
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 0;
}

.ant-picker-cell .ant-picker-cell-inner {
    width: 40px;
    height: 40px;
    line-height: 40px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.ant-picker-panel-container {
    background: #ffffff;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
}

.ant-picker-content th {
    color: #000000;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
}

.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
    background: #37c597;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: rgba(55, 197, 152, 0.3);
}

.ant-picker-header-view {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
}

.leftSidebar.Collapsed+.mainContent {
    width: calc(100% - 60px);
}

.reportDescription {
    max-width: 860px;
    width: 860px;
    display: block;
    float: left;
}

.defaultForm .searchDropDown .ant-input-search {
    background: none;
}

.ReportsSearch.searchDropDown .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    background: none;
    border-radius: 4px;
    padding: 0 10px;
}

.DocumentBtn .buttonMain {
    height: auto;
}

.DocumentBtn {
    margin: 20px 0 0;
}

.DocumentTable .ant-table-body {
    max-height: calc(100vh - 400px) !important;
}

.FormikdatePicker input {
    border: 1px solid #cecece !important;
}

.DescriptionDoc textarea {
    min-height: 150px;
}

.DocumentBtn button {
    background: #37c597 !important;
    color: #ffffff !important;
}

.DocumentTable .ant-pagination {
    display: none;
}

.DocumentsSponsorPage .DocumentSearch {
    display: inline-flex;
    width: 100%;
    justify-content: flex-end;
    margin-left: auto;
    justify-content: flex-end;
}

.ReportPageScroll {
    width: 100%;
    max-width: 100%;
}

.ReportsSearch *,
.DocumentSearch * {
    border: 0 !important;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
    border-radius: 4px;
    background: #fff;
}

.searchDropDown .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    font-style: italic;
    line-height: 40px;
}

.searchDropDown .DocumentSearch .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    height: 38px;
}

.autoCompleteSelectCont ::-webkit-input-placeholder {
    font-style: italic;
}

.autoCompleteSelectCont :-moz-placeholder {
    font-style: italic;
}

.autoCompleteSelectCont ::-moz-placeholder {
    font-style: italic;
}

.autoCompleteSelectCont :-ms-input-placeholder {
    font-style: italic;
}

::-webkit-input-placeholder {
    font-style: italic !important;
}

:-moz-placeholder {
    font-style: italic !important;
}

::-moz-placeholder {
    font-style: italic !important;
}

:-ms-input-placeholder {
    font-style: italic !important;
}

.ant-checkbox-checked::after {
    border: 0 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #37c597;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background: #37c597;
}

.DocumentsSponsorPage {
    max-height: calc(100% - 100px);
}

// .uploadImage {
// }
.uploadDocument {
    position: relative;
}

.uploadDocument input {
    cursor: pointer;
    border: 0;
}

.uploadDocument .ant-form-item-control-input-content {
    background: #ffffff;
    border: 0px solid #cecece;
    border-radius: 6px;
    background: url(../images/uploadImage.svg) no-repeat right center;
}

.iconLetBar.icon7 {
    background-image: url(../images/Investments.svg);
}
.iconLetBar.icon9 {
    background-image: url(../images/Setting.svg);
}

/* .mainMenu li a.purpleMenu.active , .mainMenu li a.purpleMenu:hover  {background: #753EE4;}*/

// .ant-tabs.ant-tabs-top.investmentTabs { top: 0;}
.ant-tabs.investmentTabs>.ant-tabs-nav .ant-tabs-nav-wrap {
    flex: none;
}

// .ant-tabs.investmentTabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {justify-content: center;}
.investmentTabs h1.card-title {
    position: relative;
    top: -60px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #753ee4;
}

.investmentTabs.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
    background: #753ee4;
    height: 4px;
    border-radius: 0;
}

.investSubHead {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #363636;
}

.investmentPage p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #5c5b62;
}

.investBanner {
    width: 100%;
    max-width: 250px;
    margin-left: auto;
    display: block;
}
.min-height-card{min-height: 480px; position: relative; max-height: 100vh;}
.disclosure_event{position: absolute; bottom: 10px; font-size: 10px;}
.disclosure_event small {
    font-size: 85%;
}
.investmentDashboardMain .disclosure_event{bottom: 10px;left: 21px;}
.investmentDashboardMain .ant-card-bordered.investMentPageSteps {margin-bottom: 20px;}
.disclosuresCheck label.ant-checkbox-wrapper {
    margin-right: 12px;
}
.disclosure_links{text-decoration: underline; color: #6565cd !important;}

.investBannerText {
    height: 100%;
    width: 100%;
    padding: 0;
}

.chooseInvest h2 {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #363636;
    margin: 0 0 30px;
}

.investmentBody {
    padding: 0 2em;
    width: 100%;
    display: block;
}

.recommeText {
    position: absolute;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #9b57ff;
    transform: rotate(-90deg);
    background: none;
    border: 1px solid #9b57ff;
    border-radius: 12px;
    border-bottom: 0;
    padding: 6px;
    left: -75px;
    top: 70px;
}

.tagTop {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    background: url(../images/starTag.svg) no-repeat center center;
    background-size: contain;
    left: 10px;
    z-index: 1;
}

.FlipCards h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    color: #ffffff;
    text-align: center;
}

.FlipCards p {
    font-weight: 300;
    font-size: 13px;
    color: #ffffff;
    text-align: left;
}

.effortMeter {
    text-align: center;
}

.effortMeter img {
    display: inline-block;
}

.effortManage {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
}

.feeFlip {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin: 12px 0 15px;
    color: #ffffff;
}

.buttonSelectEff {
    text-align: center;
}

.buttonSelectEff button {
    background: #ffffff;
    border: 1px solid #9b57ff;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #9b57ff;
    padding: 12px 51px;
    height: auto;
}

.flipToggleBtns {
    position: absolute;
    right: 20px;
    bottom: 18px;
}

.flipToggleBtns button {
    width: 6px;
    height: 6px;
    font-size: 0;
    border-radius: 100%;
    background: #fff;
    padding: 0;
    border: 0;
    margin-left: 10px;
}

.flipToggleBtns button.active {
    // background: #5823a6;
    background: #128a84;
}

.FlipVideoPdf {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FlipVideoPdf a {
    display: inline-block;
    margin: 0 12px;
}

.flipVideoIcon {
    background: url(../images/VideoPLay.svg) no-repeat center center;
    background-size: 54px;
    width: 75px;
    height: 75px;
}

.flipPdfIcon {
    background: url(../images/pdfInvestIcon.svg) no-repeat center center;
    background-size: 54px;
    width: 54px;
    height: 54px;
}

.flipPptIcon {
    background: url(../images/pptIcon.svg) no-repeat center center;
    background-size: 55px;
    width: 55px;
    height: 55px;
}

.FlipCards .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    background: #f8f3fe;
}

.FlipCards .ant-collapse>.ant-collapse-item {
    border-left: 4px solid #753ee4;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
}

.FlipCards .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #0e1c4e;
    padding-left: 40px;
}

.FlipCards h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #0e1c4e;
}

.FlipCards .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    right: auto;
    left: 10px;
    width: 26px;
    top: 6px;
    height: 26px;
    background: url(../images/iconCollapseInvClose.svg) center center;
    background-size: 26px;
}

.FlipCards .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
    display: none;
}

.FlipCards .ant-collapse>.ant-collapse-item.ant-collapse-item-active>.ant-collapse-header .ant-collapse-arrow {
    background: url(../images/iconCollapseInvOpen.svg) center center;
    background-size: 26px;
}

.contentFlip {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #0e1c4e;
    padding: 10px;
    text-align: left;
}

.FlipCards .ant-collapse>.ant-collapse-item.ant-collapse-item-active>.ant-collapse-header {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #753ee4;
    background: #ffffff;
}

.FlipCards .ant-collapse>.ant-collapse-item.ant-collapse-item-active .ant-collapse-content {
    border-top: 0;
}

.FlipVideoPdf {
    margin: 0 0 10px;
}

.FlipCards {
    width: 302px;
    height: 360px;
    display: inline-block;
    margin: 0 0;
    background: linear-gradient(200.78deg, #9a56ff 8.53%, #d78afd 91.47%), #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: relative;
}

.investmentBg {
    background-image: url(../images/investBackground.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 110%;
    height: 100%;
    width: 100%;
    padding: 12px 16px 0px;
}

.react-card-flip {
    margin: 0 20px;
    max-width: 302px;
    display: inline-block;
    vertical-align: top;
}

.chooseInvest {
    text-align: center;
}

.greenFlipCard .flipToggleBtns button.active {
    background: #128a85;
}

.greenFlipCard {
    background: linear-gradient(201.03deg, #23bdb8 7.56%, #43e794 91.99%), #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.greenFlipCard .buttonSelectEff button {
    border: 1px solid #24beb9 !important;
    color: #24beb9 !important;
}

.contentFlip {
    height: 80px;
}

.customScroll div:nth-child(3) div {
    background: #5718ae !important;
    border-radius: 2px !important;
    width: 2px !important;
}

.customScroll div:nth-child(3) {
    background: #bfb7c4 !important;
    width: 2px !important;
}

.customScroll {
    height: 150px;
}

.FlipCards.greenFlipCard .ant-collapse>.ant-collapse-item.ant-collapse-item-active>.ant-collapse-header {
    color: #37c597;
}

.greenFlipCard .customScroll div:nth-child(3) div {
    background: #128a85 !important;
}

.FlipCards.greenFlipCard .ant-collapse>.ant-collapse-item {
    border-color: #128a85;
}

.FlipCards .ant-collapse {
    padding: 0 15px;
}

.FlipCards.greenFlipCard .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    right: auto;
    left: 10px;
    width: 26px;
    height: 26px;
    background: url(../images/iconCollapseInvCloseGreen.svg) center center;
    background-size: 26px;
}

.FlipCards.greenFlipCard .ant-collapse>.ant-collapse-item.ant-collapse-item-active>.ant-collapse-header .ant-collapse-arrow {
    background: url(../images/iconCollapseInvOpenGreen.svg) center center;
    background-size: 26px;
}

.FlipCards.FrontCard p {
    min-height: 100px;
}

ul.ant-pagination {
    display: none !important;
}

.uploadDocument {
    position: relative;
}

.rightTextDocUpload {
    position: absolute;
    right: 10px;
    top: 17px;
    font-size: 14px;
}

.defaultForm .uploadDocument input {
    height: 45px;
}

.bottomTextDocUpload {
    display: flex;
    font-size: 14px;
}

.uploadDocument input {
    padding-left: 0;
}

.descriptionBox {
    position: relative;
}

.descripTotal {
    position: absolute;
    bottom: -24px;
    right: 0;
    width: auto;
    font-size: 14px;
}

.profileFormEdit .profileRightCont {
    padding-top: 0;
    padding-bottom: 0;
}

.profileFormEdit .profileFormEdit {
    width: 50%;
}

.profileFormEdit.profileFormEdit .inputRows {
    width: 100%;
    max-width: 100%;
}

.profileFormEdit.profileFormEdit input {
    width: 100%;
    max-width: 100%;
}

.profileFormEdit.profileFormEdit .ant-select {
    width: 100%;
    max-width: 100%;
}

.profileFormEdit.profileFormEdit .inputRows .profileRightCont {
    width: 50%;
    max-width: 50%;
}

.profileFormEdit .profileLeftCont {
    padding-top: 5px;
}

.profilePageBottomSpace {
    margin-bottom: 50px;
}

.DescriptionDoc {
    position: relative;
}

.MaxCharacters {
    position: absolute;
    bottom: 0;
    right: 0;
}

.transactionTable .ant-card-body {
    overflow-y: auto;
    max-height: calc(100vh - 165px);
}

.DocumentTable {
    overflow-y: auto;
    max-height: calc(100vh - 235px);
}

.profileFormEdit.profileFormEdit .inputRows .profileRightCont {
    width: 65%;
    max-width: 65%;
}

.investTopContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 72px;
    margin-top: -42px;
}

.leftStepsCount {
    width: 50%;
    position: relative;
    display: flex;
}

.investSteps {
    width: 74px;
    height: 74px;
    position: static;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 74px;
    background-image: url(../images/Tab_01.svg);
    margin-right: 14px;
}

.investmentSteps {
    border: 1px solid #e5f0ee;
    padding: 33px 30px 80px;
    box-shadow: 0px 2px 5px rgba(9, 61, 26, 0.39);
    background: #fdfdfd;
    // padding-bottom: 146px;
}

.stepTwo {
    background-image: url(../images/Tab_02.svg);
}

.stepThree {
    background-image: url(../images/Tab_03.svg);
}

.stepRightText {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #b0b9b8;
}

.investCenterText {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    text-align: left;
    /**-- text-indent: 3%; --*/
    display: block;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
}

.investSlider {
    position: relative;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.investSlider:before {
    background: #e2e2e2;
    box-shadow: inset -1px 2px 3px rgba(137, 137, 137, 0.5);
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 100%;
    height: 8px;
}

.savingSteps {
    margin: 0 0 0;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    text-align: center;
    color: #948e8e;
    display: inline-block;
    position: relative;
    bottom: -56px;
    font-size: 16px;
    width: 110px;
}

.savingSteps.active {
    color: #37c597;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 21px;
    text-align: center;
}

.investButtons {
    width: 100%;
    display: flex;
    margin: 23px 0 0;
    text-align: right;
    justify-content: flex-end;
    align-items: center;
}

.buttonLeftInvest {
    margin-left: 10px;
    background: #efefef;
    padding: 8px 19px;
    align-items: center;
    text-align: center;
    color: rgba(106, 112, 126, 0.47);
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    display: inline-block;
    height: auto;
    border: 0;
}

.buttonLeftInvest:hover {
    color: rgba(106, 112, 126, 0.47);
    background: #efefef;
}

.investIcon {
    background: url(../images/arrowLeftInv.svg) no-repeat center center;
    display: inline-block;
    height: 11px;
    width: 18px;
    vertical-align: middle;
}

.investIcon.Right {
    background-image: url(../images/arrowRightInv.svg);
    margin-left: 1px;
}

.investIcon.Left {
    margin-right: 1px;
}

.buttonLeftInvest.active {
    background-color: #37c597;
    color: #fff;
}

.savingOakIcon {
    background: url(../images/savingOakIconInvest.svg) no-repeat center center;
    width: 50px;
    height: 50px;
    background-size: 49px;
    display: inline-block;
    position: absolute;
    left: 50%;
    margin-left: -25px;
    top: -60px;
}

.investMentPageSteps .ant-card-body {
    padding-left: 80px;
    padding-right: 80px;
}

.investmentPage .investMentPageSteps .ant-row {
    margin-bottom: 0;
}

.investSteps.stepOne {
    background-image: url(../images/Tab_01Notactive.svg);
}

.investSteps.stepOne.active {
    background-image: url(../images/Tab_01.svg);
}

.investSteps.stepTwo.active {
    background-image: url(../images/Tab_02_Green.svg);
}

.buttonLeftInvest.activeBorder .investIcon {
    background-image: url(../images/arrowLeftInactivInv.svg);
}

.investPageStepTwo .savingOakIcon {
    background-image: url(../images/OvalInvestment.svg);
    background-size: 35px;
}

.investmentTabs.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
    background: #37c597;
}

.investPageStepThree .savingOakIcon {
    background-image: url(../images/HandlerAdvance.svg);
    background-size: 94px;
    width: 94px;
    margin-left: -47px;
}

.investSteps.stepThree.active {
    background-image: url(../images/Tab_03_Green.svg);
}

.investSlider.investCheckboxCont::before {
    display: none;
}

.investSlider.investCheckboxCont {
    display: block;
    text-align: left;
}

.stepCheckBox {
    background: #ffffff;
    border: 1px solid #37c597;
    padding: 9px 11px;
    margin-right: 34px;
    margin-bottom: 27px;
    border-radius: 3px;
    display: inline-flex;
    width: 200px;
    text-align: center;
    justify-content: center;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #0e5941;
}

.stepCheckBox .ant-checkbox {
    position: absolute;
    left: 11px;
    top: 4px;
}

.stepCheckBox span {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #0e5941;
}

.recommeText {
    transform: rotate(0);
    margin-left: -64px;
    background: #ce82fe;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    left: 50%;
    top: -27px;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: #ffffff;
    border: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.chooseInvest h2 {
    margin: 0 0 35px;
}

.investmentPage p.SelectAccountType {
    margin-top: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    color: #124b26;
}

.manageTextsCon h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #363636;
    text-align: center;
}

.manageTextsCon p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #5c5b62;
    text-align: center;
    // max-width: 600px;
    margin: auto;
}

.managedSlidesRound {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.managedSlidesRound:before {
    background: #e2e2e2;
    box-shadow: inset -1px 2px 3px rgba(137, 137, 137, 0.5);
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
}

.slideDots {
    background: url(../images/roundImage.svg) no-repeat center center;
    width: 40px;
    height: 40px;
    background-size: 39px;
    display: inline-block;
    position: relative;
    left: -10px;
    margin-left: 0;
    top: -19px;
    z-index: 9;
}

.manageSliderCont {
    padding: 0 5%;
    width: 100%;
    display: flex;
    margin: 45px 0 40px;
}

.manageCardsSliderButton {
    width: 100%;
    display: flex;
    margin: 0;
}

.cardSlider {
    margin-right: 48px;
    width: 315px;
    height: 475px;
    border-radius: 10px;
    border: 1px solid #43e794;
    padding: 24px 20px;
}

.buttonTabs {
    width: 70%;
    display: block;
    float: right;
}

.slideImage {
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

.cardSlider p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #737373;
}

.slideIcons {
    width: 36px;
    height: 36px;
    display: inline-block;
    background: url(../images/Video_IconManage.svg) no-repeat center center;
    background-size: 36px;
    margin: 0 7px;
}

.iconTwo {
    background-image: url(../images/DOc_IconManage.svg);
}

.iconThree {
    background-image: url(../images/Email_IconManage.svg);
}

.slideButtons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-carousel .slick-slide img {
    display: inline-block;
}

.ant-carousel .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #e6e1e1;
    opacity: 1;
}

.ant-carousel .slick-dots li.slick-active button {
    background: #2ecbad;
}

.ant-carousel .slick-dots-bottom {
    bottom: -15px;
}

.cardSlider .ant-carousel {
    min-height: 390px;
}

.buttonTabs {
    position: relative;
}

.buttonsManageBottom {
    position: absolute;
    bottom: 60px;
    left: 0;
    text-align: right;
    width: 100%;
}

.buttonTabs h2 {
    font-weight: 400;
    word-spacing: 1px;
    font-size: 16px;
    line-height: inherit;
    color: #000000;
    margin-bottom: 24px;
}

.buttonsManage button {
    border: 1px solid #43e794;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin-right: 15px;
    padding: 10px 20px;
    height: auto;
}

.iconsManageBtn {
    width: 16px;
    height: 16px;
    background: url(../images/ManageBtnicons01.svg) no-repeat center center;
    background-size: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
}

.iconManage2 {
    background-image: url(../images/ManageBtnicons02.svg);
    background-size: 18px;
    width: 18px;
    height: 18px;
}

.iconManage3 {
    background-image: url(../images/ManageBtnicons03.svg);
    background-size: 20px;
    width: 20px;
    height: 16px;
}

.iconManage4 {
    background-image: url(../images/ManageBtnicons04.svg);
    background-size: 20px;
    width: 20px;
    height: 16px;
}

.buttonsManage button:hover,
.buttonsManage button.active {
    background: linear-gradient(201.03deg, #23bdb8 7.56%, #43e794 91.99%), #ffffff;
    color: #fff;
}

.cardSlider {
    margin-bottom: 40px;
}

.slideDots.SlideOne {
    background-image: url(../images/OvalInvestmentActive.svg);
    z-index: 1;
}

.manageSteps2 .slideDots.SlideTwo {
    background-image: url(../images/OvalInvestmentActive.svg);
}

.manageSteps2 .savingOakIcon {
    background-image: url(../images/homeIcon.png);
    background-size: 70px;
    width: 70px;
    height: 70px;
    margin-left: -35px;
    top: -67px;
}

.manageSteps2 .investSlider {
    width: 100%;
}

.manageSteps3 .slideDots.SlideThree {
    background-image: url(../images/OvalInvestmentActive.svg);
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
    border-color: #37c597;
}

.radioListManage label {
    display: block;
    font-style: normal;
    font-weight: 300;
    font-size: 15.2px;
    line-height: 18px;
    color: #7b7b7b;
    margin: 0 0 18px;
}

.ant-radio-checked+span {
    color: #37c597;
}

.shareSaleBtns {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #0a0a0a;
    width: 150px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 17px;
    position: relative;
    border: 1px solid #43e794;
    border-radius: 10px;
}

.shareSaleBtns.active,
.shareSaleBtns:hover {
    background: linear-gradient(201.03deg, #23bdb8 7.56%, #43e794 91.99%), #ffffff;
    border: 1px solid #dff0fc;
    color: #fff;
}

.saleShare {
    width: 100%;
    display: flex;
}

.checkIcon {
    position: absolute;
    bottom: -10px;
    margin-right: -5px;
}

.manageSteps4 .slideDots.SlideFour {
    background-image: url(../images/OvalInvestmentActive.svg);
}

.manageSteps5 .slideDots.SlideFive {
    background-image: url(../images/OvalInvestmentActive.svg);
}

.stepTabsFive {
    width: 100%;
    position: relative;
    height: 100%;
}

.hsaFundBtn {
    width: 100%;
    max-width: 320px;
    text-align: center;
    margin: 0 30px;
    cursor: pointer;
}

.HsaFundBalance {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: center;
    float: left;
}

.hsaFundBtn h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.75rem;
    color: #363636;
    text-align: center;
}

.hsaFundBtn p {
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 162.18%;
    color: #5c5b62;
}

.stepTabsFive {
    min-height: 530px;
}

.hsaButtonBorder {
    border: 1px solid rgba(55, 197, 151, 1);
    border-radius: 26px;
    padding: 20px;
    margin: 0 0 15px;
}

.iconShopHSA {
    background: url(../images/hsaIcon.png) center center no-repeat;
    background-size: 68px;
    width: 68px;
    height: 68px;
    display: inline-block;
    margin-right: 20px;
    vertical-align: middle;
}

.priceHSAGreen {
    border: 1px solid #37c597;
    box-sizing: border-box;
    border-radius: 26px;
    padding: 12px 21px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #37c597;
    display: inline-block;
    vertical-align: middle;
}

.priceHSATree {
    background-image: url(../images/amountIcon.png);
}

.hsaFundBtn.active .hsaButtonBorder {
    background: linear-gradient(201.03deg, #23bdb8 7.56%, #43e794 91.99%), #ffffff;
}

.hsaFundBtn.active .priceHSAGreen {
    background: #ffffff;
    border: 1px solid #37c597;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.manageSteps6 .slideDots.SlideSix {
    background-image: url(../images/OvalInvestmentActive.svg);
}

.likeDislikeBarCont {
    width: 100%;
    background-size: 100%;
    height: 165px;
    text-align: center;
    position: relative;
}

.faceIcon {
    width: 55px;
    height: 55px;
    background: url(../images/faceIcon01.svg) no-repeat center center;
    display: inline-block;
}

.Icon02 {
    background-image: url(../images/faceIcon02.svg);
}

.Icon03 {
    background-image: url(../images/faceIcon03.svg);
}

.Icon04 {
    background-image: url(../images/faceIcon04.svg);
}

.Icon05 {
    background-image: url(../images/faceIcon05.svg);
}

.SmileMain {
    display: flex;
    justify-content: center;
}

.faceIconText {
    width: 20%;
    max-width: 82px;
}

.faceTextBottom {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #7b7b7b;
    display: inline-block;
    width: 100%;
    margin-top: 30px;
}

.faceIconText {
    height: 163px;
    padding: 15px;
}

.faceIconText.active .faceTextBottom {
    color: #37c597;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
}

.ant-checkbox-inner::after {
    top: 46%;
    left: 33%;
}

.checkIcon {
    display: none;
}

.shareSaleBtns.active .checkIcon {
    display: block;
}

.priceHSAGreen {
    position: relative;
    width: 174px;
    height: 58px;
}

.priceHSAGreen input {
    width: 100%;
    position: absolute;
    height: 100%;
    border: 0;
    background: #fff;
    left: 0;
    top: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #37c597;
    padding-left: 35px;
    border-radius: 26px;
}

.dallarSign {
    position: absolute;
    top: 1px;
    left: 15px;
    z-index: 2;
    height: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #37c597;
    display: flex;
    align-items: center;
}

.manageStep7 .slideDots.SlideSeven {
    background-image: url(../images/OvalInvestmentActive.svg);
}

.manageStep7 .manageTextsCon h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #37c597;
}

.RiskQuestionsList li {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    margin: 0 0 30px;
    padding-left: 10px;
}

.RiskQuestionsList li h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    margin: 0 0 15px -10px;
}

.minTextbtn {
    display: inline-block;
    padding: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 11px;
    text-align: center;
    color: #27c2b4;
    border: 1px solid #25c0b6;
    border-radius: 10px;
}

.radioRiskList label {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    color: #7b7b7b;
}

.radioRiskList {
    margin: 0 0 15px;
}

.minMaxProfile .radioRiskList:last-child {
    margin-bottom: 0;
}

.minMaxProfile button.buttonFeel {
    padding: 6px 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid #25c0b6;
    min-height: 46px;
    vertical-align: middle;
    color: #29c5b2;
}

.minMaxProfile button.buttonFeel.active {
    font-weight: bold;
    font-size: 14px;
    color: #fff;
}

.minTextbtn {
    margin-right: 20px;
}

.maxSlider {
    width: 70%;
    display: inline-block;
    height: 33px;
}

.manageSteps1 .manageCardsSliderButton {
    min-height: 530px;
}

.buttonTabs {
    min-height: 530px;
}

.manageStep7 .stepTabsFive {
    min-height: 506px;
}

.ant-slider .ant-slider-rail {
    background: #e5f0ee;
}

.ant-slider-track,
.ant-slider:hover .ant-slider-track {
    background: #3ee19a;
}

.ant-slider-handle {
    background: #3ee19a;
    border-color: #3ee19a;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #3ee19a;
}

.ant-slider * {
    border-color: #3ee19a !important;
}

.maxSlider .ant-slider-rail,
.maxSlider .ant-slider-track {
    height: 33px;
}

.maxSlider .ant-slider-handle {
    background: #fff;
    width: 33px;
    height: 33px;
    margin-top: 0;
}

.maxSlider .ant-slider-handle {
    border-width: 10px;
    border-color: #147064 !important;
}

.maxSlider .ant-slider-rail,
.maxSlider .ant-slider-track {
    border-radius: 10px;
}

.LikeSliderTrack {
    width: 100%;
    position: absolute;
    top: 50%;
    background: #e2e2e2;
    box-shadow: inset -1px 2px 3px rgba(137, 137, 137, 0.5);
    height: 7px;
    border-radius: 3.95823px;
}

.faceIconText.active {
    position: relative;
}

.faceIconText.active::before {
    width: 60px;
    height: 24px;
    background: url(../images/SlideActive.png) no-repeat center center;
    background-size: cover;
    content: "";
    position: absolute;
    top: 48%;
}

.ManageFaceSlider .ant-slider-handle {
    border-radius: unset !important;
    border: none !important;
    width: 49px !important;
    height: 24px;
    background: url(../images/SlideActive.png) no-repeat center center !important;
    background-size: contain !important;
    top: 0px !important;
    z-index: 999;
}

.investSlider.marketSlider.ManageFaceSlider.facSlide .faceIconText.active::before {
    display: none;
}

.investSlider.marketSlider.ManageFaceSlider.facSlide .ant-slider {
    width: 100%;
    position: absolute;
    left: 0%;
    z-index: 99;
}

.faceIconText {
    cursor: pointer;
}

// .likeDislikeBarCont * {-webkit-transition: all 0.5s ease-in-out;  -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out;  -o-transition: all 0.5s ease-in-out;
// transition: all 0.5s ease-in-out;}
.investSlider .ant-slider {
    width: 99%;
    position: absolute;
    left: 0%;
    z-index: 99;
}

.investSlider .ant-slider-handle {
    width: 50px;
    height: 50px;
    background: url(../images/savingOakIconInvest.svg) no-repeat center center;
    border: 0;
    border-radius: 0;
    top: -14px;
}

.savingSteps.step3 {
    position: relative;
    right: -10px;
}

// .savingOakIcon {display: none ;}
.savingSteps.step2 {
    position: relative;
    right: -15px;
}
.bank-screen {
  padding: 20px 0;
}
.addbank{text-align: center; margin-top: 20px;}
.logo-text-section h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 44px;
  color: #2d3b55;
  margin-bottom: 30px;
}
.investSlider .ant-slider-track,
.investSlider .ant-slider:hover .ant-slider-track,
.investSlider .ant-slider .ant-slider-rail {
    background: transparent !important;
}

.savingSteps {
    bottom: 0;
    height: 50px;
    padding-top: 50px;
    cursor: pointer;
}

.savingSteps .savingOakIcon {
    position: absolute;
    bottom: 0;
    top: auto;
}

.savingSteps a {
    display: none;
}

.savingSteps.active a {
    display: block;
}

.RiskQuestionsList {
    margin-bottom: 40px;
}

.RiskQuestionsList {
    margin-bottom: 90px;
    display: inline-block;
}

.savingSteps.active .savingOakIcon {
    display: block;
}

.minMaxProfile button.buttonFeel:hover {
    color: #fff;
}

.investmentBg {
    background-image: none;
}

.recommeText {
    background-color: #9a56ff;
    box-shadow: none;
}

.greenFlipCard .recommeText {
    background: #23bdb8;
}

.portfolioBtns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.portfolioBtn {
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #43e794 !important;
    height: auto;
    margin: 0 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: #126943 !important;
    text-align: center;
    padding: 0;
    min-width: 220px;
    height: 75px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
}

.iconBtnPort {
    background: url(../images/portfolioIcon1.svg) center center no-repeat #c89dff;
    width: 55px;
    height: 55px;
    background-size: 32px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 100%;
    margin-right: 13px;
}

.greenPortBtn span {
    display: block;
}

.bluePortBtn .iconBtnPort {
    background-image: url(../images/portfolioIcon2.svg);
    background-color: #26a5ff;
}

.portfolioCharts {
    width: 100%;
    display: flex;
    height: auto;
    flex-wrap: wrap;
    margin: 90px 0 30px;
}

.currentLeft {
    width: calc(50% - 40px);
    float: left;
    margin: 0 20px;
    // box-shadow: 0px 2px 5px rgba(79, 150, 103, 0.39);
    // border: 1px solid #3bc9db;
    // border: 1px solid #4de69f;
    position: relative;
    // min-height: 280px;
    min-height: 200px;
}

.curentPortLabel {
    background: linear-gradient(201.03deg, #23bdb8 7.56%, #43e794 91.99%), #ffffff;
    position: absolute;
    left: 50%;
    margin-left: -100px;
    white-space: nowrap;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    text-align: center;
    padding: 8px 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    margin-top: -40px;
}

.portChartContainer {
    width: 100%;
    display: flex;
    align-items: center;
}

.portfolioChartText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    color: #1e2022;
    white-space: nowrap;
}

.dotsPurple {
    width: 16px;
    height: 16px;
    background: #7d217a;
    border-radius: 100%;
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
}

.portfolioChart {
    display: flex;
    width: 60%;
    align-items: center;
}

.portChartContainer {
    width: 100%;
    display: flex;
    align-items: center;
}

.chartCurrent {
    height: 100%;
    display: flex;
    align-items: center;
}

.currentLeft {
    border-radius: 5px;
}

.chartRecomended {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
}

.PortChartTextList {
    width: 100%;
    display: flex;
    margin: 0 0 20px;
}

.dotsPurple.Red {
    background: #dc2837;
}

.dotsPurple.Blue {
    background: #26005d;
}

.dotsPurple.Green {
    background: #385854;
}

.TablePortfolio {
    width: 100%;
    display: block;
    margin: 0 0 140px;
}

.portfolioBtn.purplePortBtn {
    color: #7433c8 !important;
    border-color: #7433c8 !important;
}

.portfolioBtn.bluePortBtn {
    color: #19669c !important;
}

.currentLeft.rightRecom .curentPortLabel {
    margin-left: -136px;
}

// .TablePortfolio a {
//   color: #0641e4;
// }
.recommeText {
    background-color: #ce82fe;
}

.manageSteps2 .slideDots.SlideTwo {
    position: relative;
}

// .manageSteps2 .slideDots.SlideTwo::before , .manageSteps3 .slideDots.SlideThree:before, .manageSteps4 .slideDots.SlideFour:before, .manageSteps5 .slideDots.SlideFive:before, .manageSteps6 .slideDots.SlideSix:before{    position: absolute;  left: -500%;  background: #38c8b7;  height: 9px;  content: '';  width: 550%;  top: 14px;border-top-left-radius: 3.95823px; border-bottom-left-radius: 3.95823px; border-radius:  3.95823px; }
.investSlider .ant-slider-rail,
.investSlider .ant-slider-step {
    right: 6px;
}

.manageSteps6 .slideDots.SlideSix:before {
    width: 600%;
}

.fundingAmountRightDiv p {
    text-align: left;
}

.disclosuresCheck {
    font-style: normal;
    font-size: 16px;
    line-height: 141.99%;
    color: #414141;
    margin: 20px 0 0;
}

.manageSteps2 .managedSlidesRound:after {
    background: #38c8b7;
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 25%;
    height: 2px;
}

.manageSteps3 .managedSlidesRound:after {
    background: #38c8b7;
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 60%;
    height: 2px;
}

.manageSteps4 .managedSlidesRound:after {
    background: #38c8b7;
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 60%;
    height: 2px;
}

.manageSteps5 .managedSlidesRound:after {
    background: #38c8b7;
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
}

.manageSteps6 .managedSlidesRound:after {
    background: #38c8b7;
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
}

.investSliderTwo.investSliderTwo .savingSteps.step2 {
    position: relative;
    right: 1.1%;
}

.investSliderTwo .savingSteps.step3 {
    position: relative;
    left: 2%;
}

.investSliderTwo .savingSteps.step4 {
    right: -3.8%;
}

.investSlider .ant-slider-handle {
    width: 39px;
    height: 39px;
    background: url(/static/media/savingOakIconInvest.74ad52f6.svg) no-repeat top center;
    top: -9px;
    background-size: contain;
}

.investmentPageOne .savingSteps.step2 {
    right: 2.5%;
}

.investmentPageOne .savingSteps.step3 {
    right: 1.5%;
}

.investmentPageOne .savingSteps.step4 {
    right: 0%;
}

.investmentPageOne .savingSteps.step1 {
    right: 0%;
}

.investmentPageOne .savingSteps {
    z-index: 1;
}

.investSliderTwo.managedSlider .savingSteps.step3 {
    position: relative;
    left: -2.7%;
}

.investSliderTwo.managedSlider .savingSteps.step2 {
    position: relative;
    right: 5.1%;
}

.investSliderTwo.managedSlider .savingSteps.step4 {
    right: -2.8%;
}

.investSliderTwo.managedSlider .savingSteps.active {
    font-size: 16px;
}

.investSliderTwo.managedSlider .savingSteps {
    font-size: 16px;
}

.arrow {
    vertical-align: middle;
}

.investSteps.stepThree {
    display: none;
}

.dotsPurple {
    background: #6666ce;
}

.dotsPurple.Red {
    background: #00bee5;
}

.dotsPurple.Blue {
    background: #ffb929;
}

.dotsPurple.Green {
    background: #3be8b0;
}

.onlyBorder .iconShopHSA {
    background-image: url(../images/briefcase.svg);
    background-size: 25.5px;
    border: 1px solid #37c597;
    border-radius: 100%;
}

.discSpace {
    display: inline-block;
    padding: 0 14px;
}

.manageSteps2 .managedSlidesRound:after {
    background: #35b289;
}

.marketSlider.investSlider .ant-slider-handle {
    width: 24px;
    height: 24px;
    border: 1px solid #35b289;
    border-radius: 100%;
    background: #fff;
    top: -2px;
    z-index: 999;
}

.managedSlidesRound .slideDots:last-child {
    margin-right: -19px;
}

.slideDots {
    background-size: 24px;
}

.greenPortBtn span.iconBtnPort.FirstAmount {
    background-image: url(../images/amountIconFirst.png);
    width: 55px;
    background-color: #39daa0;
}

.yellowBtn span.iconBtnPort.currentAge {
    background-image: url(../images/portfolioIcon3.svg);
    width: 55px;
    background-color: #0095ff;
}

.hsaFundBtn h3 {
    color: #37c597;
}

.portfolioBtn.yellowBtn {
    color: #0095ff !important;
    border-color: #0095ff !important;
}

.hsaFundBtn.active.fundingAmountRightDiv h3 {
    font-weight: bold;
}

.PDFIcon:hover {
    background-image: url(../images/PdfActive.svg);
    cursor: pointer;
    background-size: 14px;
}

.disableBtnEdit,
.disableDeleteBtn {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    text-align: left;
}

.settingForm.EditSettingForm.editSettingDisable .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    text-align: right;
}

.investDashHead h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #2d3b55;
    margin-bottom: 20px;
}

.balanceCards {
    display: flex;
}

.investSec {
    width: auto;
    padding: 0 50px;
    float: left;
    position: relative;
}

.investSec:first-child {
    padding-left: 0;
}

.investSec:after {
    content: "";
    width: 1px;
    height: 80%;
    background: rgba(134, 141, 149, 0.5);
    position: absolute;
    top: 10%;
    right: 0;
}

.investSec:last-child:after {
    display: none;
}

.investSec h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #2d3b55;
}

.investSec h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: rgb(102, 102, 206);
}

.heightAuotoCard {
    height: auto;
}

.allocationTable {
    margin: 30px 0 0;
}

.tableBar {
    width: 100%;
    display: flex;
    position: relative;
    background: #e2e2e2;
    box-shadow: inset -1px 2px 3px rgba(137, 137, 137, 0.5);
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    overflow: hidden;
    margin: 5px 0 0;
}

.tableBarInner {
    width: 20%;
    display: flex;
    height: 2px;
    position: absolute;
    left: 0;
    top: 0;
    background: #35b289;
}

.barWithText {
    display: block;
    width: 100%;
    text-align: center;
}

.iconTopArrowGreen {
    width: 20px;
    height: 20px;
    background: url(../images/arrowUpGreen.svg) center center no-repeat;
    background-size: 18px;
    display: inline-block;
    margin-right: 5px;
}

.arrowUpGreen {
    width: 12px;
    height: 12px;
    background: url(../images/arrowUpGreen.svg) center center no-repeat;
    background-size: 10px;
    display: inline-block;
    margin-right: 5px;
}

.arrowDownRed {
    width: 12px;
    height: 12px;
    background: url(../images/downArrowRed.svg) center center no-repeat;
    background-size: 10px;
    display: inline-block;
    margin-right: 5px;
}

.tableLink {
    color: #37c597;
}

.investBottomCenterText {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.InvestmentChart {
    position: relative;
}

.portfolioText {
    font-style: normal;
    font-weight: 900;
    margin: 0;
    font-size: 24px;
    line-height: 100%;
    color: #2d3b55;
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.InvestmentChart span {
    font-size: 18px;
    width: 100%;
    display: block;
}

.portfolioTabs {
    margin-top: 64px;
}

.portfolioTabs .ant-tabs.ant-tabs-top {
    position: static;
}

.portfolioTabs .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: center;
}

.portfolioTabs .ant-tabs-tabpane {
    padding: 20px;
    border: 1px solid #37c597;
    border-top: 0;
}

.portfolioTabs .ant-tabs-tab {
    margin-left: 9px !important;
    border: 1px solid #8c8c8c3d !important;
    border-bottom: 1px solid #37c597 !important;
    background: #ffffff !important;
    padding: 11px 22px !important;
}

.portfolioTabs .ant-tabs-nav {
    margin-bottom: 0 !important;
}

.portfolioTabs .ant-tabs-nav::before {
    border-bottom: 1px solid #37c597 !important;
}

.portfolioTabs .ant-tabs-content {
    padding-left: 0;
    padding-right: 0;
}

.portfolioTabs .ant-tabs-tab.ant-tabs-tab-active {
    border: 1px solid #37c597 !important;
    border-bottom-color: #fff !important;
}

.portfolioTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #37c597;
}

.portfolioTabs .ant-tabs-tab-btn {
    font-weight: 400;
}

.doItBtn {
    position: static;
}

.portfolioTabs .portfolioCharts {
    // margin: 55px 0 30px;
    margin: 30px 0 30px;
    border: 1px solid #4de69f;
    padding: 18px;
    border-radius: 5px;
}

.portfolioTabs .TablePortfolio {
    margin: 0;
}

.blueCardTop {
    background: url(../images/backgroundVector.png) no-repeat center center $shamrock !important;
    background-size: cover;
    height: 256px;
}

.inputRows lable,
.editSettingDisable .inputRows .ant-input,
.editSettingDisable .inputRows .ant-select {
    pointer-events: none;
    cursor: default;
}

.viewTransaction a.allTransaction {
    color: #2d3b55;
}

.viewTransaction a.allTransaction:hover {
    color: #37c597;
}

a:hover {
    color: #37c597;
    text-decoration: none;
}

.DocumentTable .documentName {
    display: flex;
}

.DocumentTable .documentName a {
    word-break: break-word;
}

.DocumentTable .ant-table-tbody>tr>td {
    font-size: 14px;
}

.contriButionDonut .donutChartCenter {
    min-height: 406px;
}

.ant-menu-item.EditDeleteTable:hover {
    color: #37c597;
}

button.buttonLeftInvest,
button.buttonLeftInvest:hover,
button.buttonLeftInvest:focus {
    line-height: 39px;
    font-size: 14px;
}

.buttonLeftInvest.active,
.buttonLeftInvest.active:hover,
.buttonLeftInvest.active:focus {
    line-height: 39px;
    font-size: 14px;
}

.investSlider .ant-slider-handle:focus {
    box-shadow: none;
}

.settingAdminScroll.scrollYauto .ant-table-body {
    height: auto !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.ManageFaceSlider {
    position: absolute;
    top: 87px;
    z-index: 999;
}

.likeDislikeBarCont .faceIconText.active::before {
    display: none !important;
}

.clearfix {
    clear: both !important;
}

// .investSliderTwo .ant-slider-handle { transition: all 0.5s ease-in-out;}
.usertabs .ant-tabs.ant-tabs-top {
    max-height: calc(100vh - 166px);
}

.smileMain {
    display: flex;
    justify-content: space-between;
}

.smileMain .faceIconText:first-child {
    padding-left: 0;
}

.smileMain .faceIconText:last-child {
    padding-right: 0;
}

.managedTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
    color: #37c597 !important;
}

.netBalace {
    height: 256px;
    color: #fff;
}

.netBalace h5 {
    font-size: 20px;
}

.netBalace .cashImage img {
    width: 100%;
    max-width: 326px;
}
.netBalace .disclosure_event{position: absolute;
right: 12px;
bottom: 10px;
color: #000;}

.netBlanceBtn {
    border-radius: 5px;
    width: 90%;
    max-width: 300px;
    padding: 14px 20px;
    margin: auto;
    background: linear-gradient(201.03deg, #23bdb8 7.56%, #43e794 91.99%), #ffffff;
    text-align: center;
    margin-bottom: 18px;
}

.netBlanceBtn h2 {
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0px;
}

.netBlanceBtn h4 {
    margin-bottom: 0;
    margin-top: -4px;
    font-size: 1.12rem;
    color: #fff;
}
.NetBalanceText
{
    text-align: center;
}
.NetBalanceText .priceBottom {
    font-size: 14px;
    margin-bottom: 0;
    color: #37c597;
}
.NetBalanceText.currentbalance{color: gray;}

.netBlanceInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.investNow h3 {
    font-size: 1.5rem;
    margin-bottom: 0;
    color: #fff;
    font-weight: 400;
    line-height: 32px;
    margin-top: -7px;
}

.investNow h2 {
    font-size: 1.625rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #fff;
    margin-top: -4px;
}

.investNowBtn {
    font-size: 1.25rem;
    font-weight: 700;
    background-color: #fff;
    padding: 0.4rem 1rem;
    border-radius: 5px;
    color: #37c597;
    margin-bottom: 0.68rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 165px;
}

.investNow .eligibleIcon img {
    width: 59px;
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges;
    /* Firefox */
    image-rendering: -o-crisp-edges;
    /* Opera */
    image-rendering: -webkit-optimize-contrast;
    /* Webkit (non-standard naming)*/
    -ms-interpolation-mode: nearest-neighbor;
    /* IE (non-standard property) */
}

.investNow p {
    font-size: 0.78rem;
    font-weight: 400;
    margin-top: 0.6rem;
    color: #fff;
}

.investPageStepTwo .investSlider .ant-slider-handle,
.investSliderTwo.managedSlider .investSlider .ant-slider-handle {
    background: url(../images/savingOakIconInvest.svg) 50% center / contain no-repeat !important;
}

.investSlider.investSliderTwo .ant-slider-handle {
    background: url(../images/savingOakIconInvest.svg) no-repeat 50% !important;
    background-size: contain !important;
}

.eligibleHeading {
    font-size: 1.25rem;
}

.netBlanceBtn.EligibleWhite {
    background: #fff;
}

.netBlanceBtn.EligibleWhite h2 {
    color: #37c597;
    margin-bottom: 0px;
}

.netBlanceBtn.EligibleWhite h4 {
    color: #37c597;
}

.ExpandViewBtn {
    line-height: 1.6;
    display: inline-block;
    color: #fff;
    font-size: 0.75rem;
    background-color: #9ca3a1;
    padding: 0.2rem 0.8rem;
    border-radius: 1rem;
    font-weight: 700;
}

.ExpandViewBtn:hover,
.ExpandViewBtn:focus {
    background: #37c597;
    transition: 0.3s all;
}

.investmentsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.625rem;
}

.investColoCards {
    padding: 1.7rem 1rem 1rem;
    border-radius: 5px;
    margin: 0 0.59rem;
    text-align: center;
}

.investColoCards h3 {
    line-height: 16px;
    font-size: 1.13rem;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0.8em;
}

.investColoCards h2 {
    line-height: 35px;
    font-size: 26px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0.3em;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    line-height: 25px;
    margin-bottom: 12px;
    color: #ffffff;
    height: 30px;
}

.investColoCards:first-child {
    margin-left: 0;
}

.investColoCards:last-child {
    margin-right: 0;
}

.investmentsImg {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    width: 31%;
    max-width: 48px;
    height: 48px;
    object-fit: contain;
}

.investColorCardMain {
    display: flex;
    justify-content: space-between;
}

.investColorCardMain .investColoCards {
    width: 32%;
}

h2.investmentsHeading:before {
    content: "";
    position: relative;
    left: -10px;
    top: 0px;
    height: 30px;
    width: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../../assets/images/arrow/arrow.png');
    // border-left: 9px solid #ffffff00;
    // border-right: 10px solid #ffffff00;
    // border-top: 0px solid #ffffff00;
    // border-bottom: 18px solid #fff;
    clear: both;
    display: inline-block;
    z-index: 999;
}

h2.investmentsHeading.minusTicker:before {
    content: "";
    position: absolute;
    left: -10px;
    top: 0px;
    height: 30px;
    width: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../../assets/images/arrow/arrow.png');
    clear: both;
    display: inline-block;
    z-index: 999;
    transform: scale(-0.95);
}

.investmentsHeading {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-left: 0.687rem;
}

.InvestmentHeader h2 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.68rem;
    margin-bottom: 0;
}

.InvestmentHeader {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;
}

.InvestmentHeader .date {
    color: #8998ac;
    font-size: 0.75rem;
}

.InvestmentsCard>.ant-col {
    padding: 0 8px !important;
}

.InvestmentsCard>.ant-col:first-child {
    padding-left: 0 !important;
}

.InvestmentsCard>.ant-col:last-child {
    padding-right: 0 !important;
}

.InvestMentCardInner {
    padding: 1.5rem 1rem 1.9rem;
    border-radius: 5px;
    text-align: center;
}

.InvestMentCardInner h3 {
    line-height: 16px;
    font-size: 1.16rem;
    font-weight: 700;
    color: #fff;
    //  margin-bottom: 2.1rem;
    // position: absolute;
    // left: 0;
    // right: 0;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    line-height: 25px;
    margin-bottom: 12px;
    color: #ffffff;
    height: 30px;
}

.counterTitle img {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    width: 31%;
    max-width: 31.87px;
    height: 31.87px;
    object-fit: contain;
}

.counterTitle {
    justify-content: center;
    align-items: center;
    margin-top: 1.3rem;
}

.counterTitle h2 {
    line-height: 35px;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0em;
    margin-left: 0.5rem;
}

.investmentsCenterData .ant-card {
    height: 100%;
}

.ant-row.InvestmentsCard {
    margin-bottom: 2.6rem;
}

.investmentsCenterData .colorBox {
    border-radius: 100%;
}

.timesinfor a {
    padding: 0.6rem 1.3rem;
    border: 1px solid #cad7df;
    border-collapse: collapse;
    border-right: 0;
    color: #737588;
}

.timesinfor a:last-child {
    border-right: 1px solid #cad7df;
    border-radius: 0px 5px 5px 0px;
}

.timesinfor a:first-child {
    border-radius: 5px 0px 0px 5px;
}

.timesinfor a:hover,
.timesinfor a:focus,
.timesinfor a.active {
    color: #37c597;
}

.threeDotMenu {
    background-image: url(../images/OvalThreeDot.svg);
    background-repeat: no-repeat;
    padding: 0.5rem;
    display: inline-block;
    background-size: contain;
}

.barChartHeader {
    justify-content: space-between;
    align-items: flex-start;
    margin: 0.5rem 0 2rem 0;
}

.investmentsCenterData .chartValueText {
    margin-top: 3rem;
}

.investmentBotomTable .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
}

.investmentBotomTable
{
  margin-top: 20px;
}
.eligibleExpenseChart .chartValueText {
    padding: 0;
}

.inputRows.plan_checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputRows.plan_checkbox .plan_name_checkbox {
    text-align: right;
    display: block;
}

.investmentBotomTable .ant-table-body {
    overflow: hidden !important;
    overflow-y: auto !important;
}

.emailSettingsMain .profileRightCont label.SwitchDefault {
    margin-top: 0;
}

.eligibleBottomSection .monthBox {
    height: 47.5%;
}

.investmentsCenterData .ant-card-bordered {
    border: 1px solid #c4c4c4;
}

.card.cardWhite.eligibleExpenseChart {
    height: 100%;
}

.eligibleBottomSection .flexMiddle.messageCenter {
    height: 100%;
}

.RemindParticipantModal .bodyText p {
    font-size: 1.25rem;
    color: #49505a;
    line-height: 1.5rem;
}

.RemindParticipantModal .footerText a:not(.footerText a:first-child) {
    margin: 0.3rem 0.5rem;
    margin-left: 1.31rem;
    margin-right: 0;
}

.RemindParticipantModal .footerText a {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
}

.RemindParticipantModal .footerText {
    margin-top: 1rem;
}

.RemindParticipantModal .ant-modal-title {
    font-size: 1.5rem;
}

.RemindParticipantModal .footerText {
    flex-wrap: wrap;
}

.errorTitle {
    line-height: 1.375rem;
    font-size: 1.25rem;
    font-weight: 700;
}

.errorImg {
    background-image: url("../images/errorImg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 2.5rem;
    height: 3rem;
    background-position: center;
    max-height: 27px;
}

.verifyError .errorImg {
    margin-right: 0.75rem;
}

.verifyError {
    max-width: 420px;
    width: 100%;
    border: 2px solid #fe9a20;
    padding: 1.5rem;
    border-radius: 5px;
    background-color: #ffefdb;
    margin-bottom: 1rem;
}

.ErrorDescription {
    font-size: 0.75rem;
}

.RemindParticipantModal .footerText a {
    padding: 0.5rem 1.25rem;
    font-size: 1.125rem;
    line-height: 25px;
    margin-left: auto;
}

// .ant-modal.RemindParticipantModal {min-width: 576px;}
.ant-table-thead>tr>th,
.ant-table-column-sorters-with-tooltip {
    background: #fff;
}

.pendingParticipant button.btnGrey:hover {
    border-color: #898e94 !important;
}

.pendingParticipant button.btnGrey:focus {
    border-color: inherit !important;
}

.pendingParticipant button.btn-outline-primary:hover,
.pendingParticipant button.btn-outline-primary:focus {
    border-color: $primaryColor !important;
}

.RemindParticipantModal a.buttonMain span.ant-spin-dot.ant-spin-dot-spin .ant-spin-dot-item {
    background-color: #ffffff;
}

.profilePageTab.usertabs .ant-tabs.ant-tabs-top {
    max-height: calc(100vh - 109px);
}

.statementPref#containerElement {
    max-height: calc(100% - 0px);
}

.sponsorProfile.profileTopBar.containerNew.scrollViewMain.scrollDashboard {
    margin-top: 1rem;
    padding-top: 0;
    max-height: calc(100vh - 163px);
}

.sponsorPref.profileTopBar {
    margin-top: 1rem;
    max-height: calc(100vh - 163px);
}

.DocumentTable .ant-table-tbody>tr.ant-table-row>td>.documentName>a+.PDFIcon {
    visibility: hidden;
}

.DocumentTable .ant-table-tbody>tr.ant-table-row:hover>td>.documentName>a+.PDFIcon {
    visibility: visible;
}

.notifivationMain .alertData {
    max-height: 35vh;
    max-height: 368px;
    overflow-y: scroll;
    margin-top: 1rem;
}

.notifivationMain .alertText {
    font-size: 14px;
    font-weight: normal;
}

.bottomFilterMain .ant-card-body {
    padding-left: 2rem;
    padding-right: 2rem;
}

.bottomFilterMain {
    margin-top: 2.68rem;
}

.investBannerText h2.investSubHead+p {
    color: #5c5b62;
}


/*******************************************filter*componets*********************************************************************/

.bottomFilterMainHeader {
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(224, 224, 224, 0.64);
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
}

.PrimaryTwoSwitch {
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        color: #fff;
        background: #37c597;
        border-color: #37c597;
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: #fff;
        background: #37c597;
        border-color: #37c597;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
        background-color: #40a9ff00;
    }
    .ant-radio-button-wrapper:hover {
        position: relative;
        color: #37c597;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: #37c597;
    }
    .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
        border-right-color: #37c597;
    }
    .ant-radio-button-wrapper {
        // &:hover{color: inherit;}
        font-size: 14px;
        height: 39px;
        min-width: 118.39px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        box-shadow: none;
        font-weight: 600;
        border: 1px solid $primaryColor;
        color: $primaryColor;
    }
    .ant-radio-button-wrapper:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .ant-radio-button-wrapper:not(:first-child) {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .ant-radio-button-wrapper>.ant-radio-button {
        box-shadow: none;
    }
}

.searchFilter {
    min-width: 280px;
    display: flex;
    .ant-input,
    .ant-picker-input input {
        height: auto;
    }
    .ant-input-affix-wrapper {
        border-color: $primaryColor;
        line-height: 0;
        padding: 0;
        padding-left: 6.5px;
        border-radius: 6px;
        height: 39px;
    }
    a.prefixImg img {
        max-width: 24px;
        max-height: 24px;
    }
    a.setting_icon img {
        max-height: 19.26px;
        max-width: 19.26px;
    }
    .ant-input-suffix {
        padding: 0.5rem;
        align-items: center;
        background-color: $primaryColor;
        border-radius: 0 4px 4px 0;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: $primaryColor;
    }
}

.ButtonFilterHeader {
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.75rem;
    align-items: baseline;
    .multiBtnBar {
        flex-wrap: wrap;
    }
    .multiBtnBar .btn {
        margin: 0.3125rem 0.6125rem;
        margin-left: 0;
    }
    a.out-Grey-btn.btn.active {
        background-color: #fff;
        border-color: $primaryColor;
        color: $primaryColor;
    }
}

.rangeFilter {
    max-width: 282px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    filter: drop-shadow(0px 0.545045px 1.09009px rgba(97, 97, 97, 0.2)) drop-shadow(0px 1.09009px 2.18018px rgba(97, 97, 97, 0.2));
    background-color: #fff;
    padding: 0.687rem 0.687rem;
    border-radius: 6px;
    .ant-slider-handle {
        &:focus {
            box-shadow: 0 0 0 5px rgba(55, 197, 151, 0.5);
        }
        background-color: #fff;
        border: 0;
        filter: drop-shadow(0px 0.545045px 1.09009px rgba(97,
        97,
        97,
        0.2)) drop-shadow(0px 1.09009px 2.18018px rgba(97,
        97,
        97,
        0.2));
    }
    .ant-slider {
        width: 100%;
        max-width: 216px;
    }
    .RangePrefix,
    .RangePostfix {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ant-slider-track,
    .ant-slider-step,
    .ant-slider-rail {
        height: 2px;
    }
    .RangePrefix {
        background-image: url(../images/smallGrid.svg);
    }
    .RangePostfix {
        background-image: url(../images/largeGrid.svg);
        width: 18px;
        max-height: 18px;
        height: 18px;
    }
    .RangePrefix,
    .RangePostfix {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
    }
    .RangePrefix {
        width: 11px;
        max-height: 11px;
        height: 11px;
    }
    .ant-slider {
        margin: 0px 0.375rem 0px;
    }
}

.ComapnyCardWrappersMain {
    border-radius: 6px;
    background-color: #f4f4f4;
    padding: 0.75rem 0.5rem 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    max-height: calc(100vh - 344px);
    overflow: hidden;
    overflow-y: auto;
    // padding-right: 1rem;
    .CompanyCardViewItm {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(20% - 0.8rem);
        // margin: 0.7rem 0.8rem;
        // margin: auto;
        // margin-left: auto;
        margin: 6px;
        margin-bottom: 15px;
        background-color: #fff;
        padding-top: 0.5rem;
        border-radius: 10px;
        box-shadow: 0px 4px 4px #0000001f;
        // box-shadow: 2px 8px 7px #0000001f;
        .companyCardHeader {
            display: inline;
            display: flex;
            margin-bottom: 0.5rem;
            padding: 0rem 0.5rem;
            .brandinfo {
                align-items: center;
                display: flex;
                .brandLogo {
                    //  background-image: url(../images/brandLogo.png);
                    background-size: contain;
                    width: 36px;
                    height: 36px;
                    background-repeat: no-repeat;
                    img {
                        height: 100%;
                        object-fit: contain;
                    }
                }
                h4 {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 0;
                    margin-left: 0.3rem;
                    color: #414d55;
                    &:hover a {
                        color: #2d3b55;
                    }
                }
            }
        }
        .brandName h4:hover a {
            color: #2d3b55;
        }
        .settingButtons {
            margin-left: auto;
            .PlusBtn,
            .viewBtn,
            .CheckBtn {
                background-size: contain;
                background-repeat: no-repeat;
                width: 25px;
                height: 25px;
                display: inline-block;
            }
            .PlusBtn {
                background-image: url(../images/plus-sign_green_grey_noBDR.png);
            }
            .viewBtn {
                background-image: url(../images/expand-button-grey.png);
                margin-left: 0.3rem;
            }
            .CheckBtn {
                background-image: url(../images/Green-check-box_a.png);
            }
        }
        .CopanyTitle {
            h5 {
                font-weight: normal;
                font-size: 0.875rem;
                line-height: 20px;
                padding: 0rem 0.5rem;
                margin-bottom: 0.5rem;
                color: #5f5f5f;
            }
        }
        .companyCardFooter {
            display: flex;
            justify-content: space-between;
            background-color: #f2f1f1;
            padding: 0.3rem 1rem;
            border-radius: 0 0 6px 6px;
            .price,
            .today {
                text-align: center;
            }
            .price span,
            .today span {
                color: #969696;
                font-weight: normal;
                font-size: 11px;
                line-height: 18px;
            }
            .today {
                .upSign {
                    display: inline-block;
                    background-image: url(../images/PolygonUp.svg);
                    width: 14px;
                    height: 14px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center bottom;
                }
            }
            .price h6,
            .today h6 {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #5f5f5f;
            }
        }
    }
    /***********************large*List*view*ends****************************/
    .CompanyListViewItm {
        padding: 22px 0.5rem;
        background-color: #fff;
        border-radius: 10px;
        width: 100%;
        box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
        display: flex;
        margin-bottom: 0.625rem;
        flex-wrap: wrap;
        .companyCardHeader {
            display: flex;
            .brandinfo {
                display: flex;
                align-items: center;
                .brandLogo {
                    height: 56px;
                    width: 56px;
                    img {
                        width: 100%;
                        object-fit: contain;
                    }
                }
                .brandName {
                    margin-left: 1rem;
                    h4 {
                        font-weight: bold;
                        font-size: 1.125rem;
                        line-height: 1.25rem;
                    }
                    .CopanyTitle {
                        font-weight: normal;
                        font-size: 1rem;
                        line-height: 1.25rem;
                        margin-bottom: 0;
                    }
                }
            }
        }
        .DataSetings {
            display: flex;
            margin-left: auto;
            align-items: center;
            .dataItm {
                padding: 0 1.5rem;
                border-left: 1px dashed #a2c0d4;
            }
        }
    }
}

.DataSetings .dataItm span {
    font-weight: normal;
    font-size: 0.812rem;
    line-height: 1.125rem;
    letter-spacing: -0.1px;
    color: #969696;
    word-break: break-word;
}

.dataItm h6 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.187rem;
    color: #5f5f5f;
}

.settingButtons.dataItm a:last-child {
    margin-left: 1.3rem;
}

.DataSetings .dataItm h6 {
    margin-top: 0.4rem;
}

.DataSetings .dataItm {
    text-align: center;
}

.settingButtons.dataItm {
    height: 100%;
    display: flex;
    align-items: center;
}


/***********************large*list*view*ends****************************/


/**********************************************filter*componets*ends******************************************************************/


/***********************large*grid*view*****************************/

.ComapnyCardWrappersMain.CardGridView {
    .companyCardHeader {
        .brandinfo {
            align-items: flex-start;
            .brandLogo {
                height: 46px;
                width: 96%;
                img {
                    height: 100%;
                    object-fit: contain;
                }
            }
            .brandName {
                h4 {
                    font-size: 1.125rem;
                }
            }
        }
    }
}


/**********************large*grid*view******************************/

.ButtonFilterHeader .px0 {
    padding-left: 0px !important;
    padding-right: 0 !important;
}

.ButtonFilterHeader .multiBtnBar .btn:first-child {
    margin-left: 0;
}

.addCheck {
    display: inline-flex;
    margin-right: 0.3rem;
    background-image: url(../images/Checked.svg);
    background-size: contain;
    background-repeat: no-repeat;
    min-width: 14px;
    height: 10.5px;
}


/******************* Card's Bigger ***********************/

.marketCap span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #969696;
}

.CardGridView .CompanyCardViewItm {
    width: 100%;
    max-width: calc(33.33% - 1.3333rem);
    margin-right: 2rem;
    margin-left: 0;
}

.marketCap h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    color: #5f5f5f;
    text-align: center;
}

.ComapnyCardWrappersMain.CardGridView .CompanyCardViewItm .settingButtons {
    max-width: 30px;
}

.ComapnyCardWrappersMain.CardGridView .CompanyCardViewItm .settingButtons a {
    margin: 0 0 10px;
}

.ComapnyCardWrappersMain.CardGridView .CompanyCardViewItm .settingButtons a:last-child {
    margin: 0;
}

.ComapnyCardWrappersMain.CardGridView .CompanyCardViewItm .companyCardHeader .brandinfo {
    flex-direction: column;
}

.ComapnyCardWrappersMain.CardGridView .CompanyCardViewItm .companyCardHeader .brandLogo {
    margin-bottom: 10px;
}

.ComapnyCardWrappersMain.CardGridView .CompanyCardViewItm .companyCardFooter {
    background: none;
    border-top: 1px dashed #a2c0d4;
    margin: 0 10px;
    padding: 0.3rem 0.5rem;
    margin-top: 10px;
}

.ComapnyCardWrappersMain.CardGridView .CompanyCardViewItm .CopanyTitle h5 {
    font-size: 1rem;
}

.ComapnyCardWrappersMain.CardGridView .CompanyCardViewItm .companyCardFooter .price span,
.ComapnyCardWrappersMain.CardGridView .CompanyCardViewItm .companyCardFooter .today span {
    font-size: 13px;
    color: #969696;
}

.ComapnyCardWrappersMain.CardGridView .CompanyCardViewItm .companyCardFooter .price h6,
.ComapnyCardWrappersMain.CardGridView .CompanyCardViewItm .companyCardFooter .today h6 {
    font-size: 15px;
    color: #5f5f5f;
}

.ComapnyCardWrappersMain.CardGridView .CompanyCardViewItm .companyCardHeader .brandinfo h4 {
    margin-left: 0;
}

.CardGridView .CompanyCardViewItm:nth-child(3n+3) {
    margin-right: 0;
}


/******************* Card's Bigger ***********************/

.seffDirectedMain .fundingAmountRightDiv {
    margin-top: 2rem;
}

.today {
    .upSign {
        display: inline-block;
        background-image: url(../images/PolygonUp.svg);
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center bottom;
    }
}

.PlusBtn,
.viewBtn,
.CheckBtn {
    background-size: contain;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    display: inline-block;
}

.PlusBtn {
    background-image: url(../images/plus-sign_green_grey_noBDR.png);
}

.viewBtn {
    background-image: url(../images/expand-button-grey.png);
    margin-left: 0.3rem;
}

.CheckBtn {
    background-image: url(../images/Green-check-box_a.png);
}

.FilterTitleMian {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 1.687rem 0;
}

.FilterTitle {
    display: flex;
    margin-right: 0.525rem;
    align-items: center;
}

.dashedLine {
    width: 100%;
    border-bottom: 1px dashed #a2c0d4;
}

.FilterTitle h4 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.1rem;
    text-transform: uppercase;
    color: #414d55;
    width: max-content;
    // letter-spacing: 0.416667px;
}

.arrowRight {
    margin: 0 0.5rem;
    color: #414d55;
}


/*************************************fund*transfer**************************************************/

.FundTrabsferBreadcrumb .ant-breadcrumb-link {
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: capitalize;
    color: #959598;
}

.FundTrabsferBreadcrumb {
    margin-bottom: 2.5rem;
}

.fundTransferHeading {
    font-weight: normal;
    font-size: 1.625rem;
    line-height: 1.875rem;
    color: #363636;
    text-align: center;
}

.fundDescription {
    font-size: 0.875rem;
    line-height: 1.437rem;
    text-align: center;
    color: #5c5b62;
}

.fundTransferStep .ant-steps {
    max-width: fit-content;
    width: 95%;
    margin: 4rem auto 4.75rem;
    justify-content: space-between;
}

.fundTransferStep .ant-steps-item {
    display: flex;
    align-items: center;
    flex: inherit;
    padding-left: 0;
}

.fundTransferStep .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 0;
}

.fundTransferStep .ant-steps-item-custom>.ant-steps-item-container>.ant-steps-item-icon>.ant-steps-icon {
    top: 0px;
    left: 0.5px;
    width: 1.25rem;
    height: 1.25rem;
    font-size: 0.875rem;
    line-height: 2rem;
    // background: $white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 100%;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
}

.fundTransferStep .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
    color: $white;
    background-color: $primaryColor;
    border-color: $primaryColor;
}

.fundTransferStep .ant-steps-item-title::after {
    position: absolute;
    bottom: auto;
    top: 10px;
    right: 0px;
    display: inline-block;
    width: 3.125rem;
    height: 0.875rem;
    background: #f0f0f0;
    content: "";
    background-image: url(../images/Arrow_dashed_right.svg);
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 0.6rem;
}

.fundTransferStep .ant-steps-item-title {
    font-size: 1.125rem;
    font-weight: normal;
    padding-right: 0;
    margin-right: 4rem;
}

.fundTransferStep .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: $white;
}

.fundTransferStep .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: $primaryColor;
    color: #37c597;
    border-bottom: 2px solid;
    font-weight: 700;
}

.fundTransferStep .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: $white;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: $white;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #ffffff;
    background: #b5bdc1;
}

.steps-action.stepBottomBtn {
    margin: 4rem auto;
    text-align: center;
    margin-top: 5rem;
}

// .iconShopHSA.priceHSATree{background-image: url(../images/investment_Wht_01_2.svg);}
.MoveMoneyBtn .fundingAmountRightDiv .iconShopHSA {
    border-radius: 100%;
    background-image: url(../images/breefCaseGradiant.svg);
    min-width: 4.25rem;
}

.MoveMoneyBtn .onlyBorder .iconShopHSA {
    background-image: url(../images/priceFlower.svg);
    // background-size: 100%;
    background-size: 68px;
    border: 0;
}

.sendFunfImg {
    width: 4.562rem;
    height: 4.562rem;
    background-image: url(../images/sendFundImg.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.full-page-scroll{overflow-y: scroll; overflow-x:hidden; height: 100%; position: relative}
.MoveMoneyBtn .HsaFundBalance {
    align-items: flex-start;
}
.full-page-scroll .login-confirm,.full-page-scroll .create-account-div {margin: 80px auto 0;}
.content-center{justify-content: center;}
.top-green-header {
  position: absolute;
  top: 0;
  background: #37C597;
  width: 100%;
  height: 100px;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}
.create-account-div .form-back{float: left; clear: both;}
.create-account-div .form-next{float: right;}
.MoveMoneyBtn .hsaFundBtn {
    // margin: 0 0.9rem;
    // max-width: 366px;
    max-width: 300px;
}

.centerFundTransfer {
    margin: 35px 0 0;
}

.MoveMoneyBtn .hsaFundBtn h3 {
    color: #363636;
}

.Fund_disclouser {
    font-size: 0.875rem;
    line-height: 162.18%;
    color: #5c5b62;
}

.sellAll label.ant-checkbox-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    height: 3rem;
}

.MoveMoneyBtn .hsaButtonBorder {
    display: inline-flex;
    align-items: center;
    width: fit-content;
}

.sellAll label.ant-checkbox-wrapper .ant-checkbox-inner {
    height: 0.875rem;
    width: 0.875rem;
    margin-bottom: 0.4rem;
    border-color: $primaryColor;
}

.sellAll .ant-checkbox-inner::after {
    left: 18%;
}

.sellAll .ant-checkbox+span {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 162.18%;
    text-align: center;
    color: #2ec19a;
}

.MoveMoneyBtn .fundingAmountRightDiv p {
    text-align: center;
}

.MoveMoenyMain {
    min-height: 100%;
}

.TransferTxt {
    margin-bottom: 3rem;
    font-size: 1rem;
    line-height: 1.375rem;
    text-align: center;
    color: #5c5b62;
}

h3.ThanksTxt {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 28px;
    text-align: center;
    color: #363636;
    margin-bottom: 3.81rem;
}

.fundTransferStep .ant-steps-item:last-child .ant-steps-item-title {
    margin-right: 0;
}

.moveMoneyWrapper {
    height: 100%;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
    color: #37c597;
}

.thanksContent {
    max-width: 400px;
    margin: auto;
}

.centerFundTransfer {
    padding: 1rem;
}


/*******************************************************************************fund*transfer*Edns*****************************************************/

.SelectElection .ant-input-number-handler-wrap {
    opacity: 1;
}

.SelectElection .ant-input-number-handler {
    border-left: 0;
}

.SelectElection .ant-input-number-handler-down {
    border-top: 0;
}

.ant-input-number.SelectElection:hover {
    border-color: $primaryColor;
}

.ant-input-number.SelectElection {
    border-radius: 6px;
}

.SelectElection .ant-input-number-input {
    text-align: center;
}

.SelectElection .ant-input-number:focus,
.ant-input-number-focused {
    box-shadow: none;
    border-color: $primaryColor;
}

.SelectElection .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
    color: $primaryColor;
}

.ant-input-number-handler-wrap {
    border-radius: 0 6px 6px 0;
}

.DisInImg {
    max-height: 215px;
    border: 2px solid;
    padding: 1rem;
    border-radius: 10px;
    min-height: 215px;
    text-align: center;
}

.DisInImg .mixed-chart {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.DisInImg .iconShopHSA {
    border-radius: 100%;
    margin: auto;
    background-image: url(../images/BlueDollerPlant.svg);
    margin-bottom: 1.75rem;
}

.DisInImg h4 {
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.43rem;
    letter-spacing: 0.1px;
    color: #3891d3;
    margin-bottom: 0.3rem;
}

.DisInImg h2 {
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2.625rem;
    letter-spacing: 0.116667px;
    color: #50b5ff;
}

.DisInImg.bc-primary.disChartMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

p.totalAllocate {
    font-weight: bold;
    font-size: 18px;
    text-align: right;
    color: #5c5b62;
}

.allocatedbtn {
    text-align: right;
}

h3.investSubHeadTwo {
    font-weight: bold;
    font-size: 18px;
    color: #686c77;
}

.TransactionMain .ant-card-body {
    max-height: initial;
}

.FundTrabsferBreadcrumb span:first-child .ant-breadcrumb-link {
    text-transform: uppercase;
}


/****************************************************************************************/

.contriButionDonut .donutChartCenter {
    flex-direction: column;
}

.inputInitialFund {
    height: 32px;
    padding: 0;
    background: none;
}

.priceHSAGreen .inputInitialFund input {
    padding-left: 2px;
    padding-right: 3px;
}

.contriButionDonut .donutCenter .expenseChart .donutCenter,
.contriButionDonut .donutCenter {
    position: static;
}

.expenseChart .donutChartCenter {
    flex-direction: column;
}

.inputInitialFund .ant-input-number-handler-wrap {
    display: none;
}


/*******************************loader*********************************************************/

.loaderMain {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #000000ad;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loaderMain .loaderInner img {
    height: 100px;
    width: 100px;
}

.loaderInner p {
    color: #fff;
    font-size: 1.2rem;
    max-width: 400px;
    text-align: center;
    width: 100%;
    margin-top: -0.5rem;
}


/****************************************************************************************/

.alertModel p {
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 24px;
    color: #2d3b55;
}

.expenseChart .donutCenter {
    position: static;
}

.participantsMainDashboard .flexMiddle.monthBox {
    height: 47.5%;
}
.participantsMainDashboard .dashbaordContri {
    height: 47.5%;
}
.participantsMainDashboard .cardWhite.messageCenter {
    height: 100%;
}

// .participantsMainDashboard .ParticipentRecenttable .ant-table-body{ max-height: 444px!important; overflow: hidden!important; overflow-y: auto!important;}
.fundingAmountRightDiv .inputInitialFund {
    width: 100%;
}
.participantsMainDashboard .contributionChart .donutChartCenter .apexcharts-canvas .apexcharts-text:not(:first-child):not(:last-child) tspan {display: none;}
.participantsMainDashboard .contributionChart .donutChartCenter .apexcharts-canvas .apexcharts-xaxis-tick:not(:first-child):not(:last-child) {display: none;}
.conributionPageChart .donutChartCenter .apexcharts-canvas .apexcharts-text:not(:first-child):not(:last-child) tspan {display: none;}
.conributionPageChart .donutChartCenter .apexcharts-canvas .apexcharts-xaxis-tick:not(:first-child):not(:last-child) {display: none;}


.fundingAmountRightDiv .priceHSAGreen .inputInitialFund input {
    padding-right: 0.55rem;
    padding-left: 0;
    background: transparent;
}

.fundingAmountRightDiv .priceHSAGreen .dallarSign {
    left: 15px;
}

.fundingAmountRightDiv .priceHSAGreen input {
    left: 16px;
    border-radius: 0;
}

button.ant-btn.buttonLeftInvest.active:disabled {
    background: #efefef;
    color: rgba(106, 112, 126, 0.47);
}

.disclosuresCheck label.ant-checkbox-wrapper .ant-checkbox {
    top: -0.2em;
}

.conributionPageChart .donutCenter {
    position: static;
}

.conributionPageChart .donutChartCenter {
    flex-direction: column;
}

.conributionPageChart .apexcharts-yaxis-label tspan,
.participantChartDashboard .apexcharts-yaxis-label tspan,
.contributionChart .apexcharts-yaxis-label tspan {
    display: none;
}

.selfInvestmentInput .priceHSAGreen input {
    background: none;
}

.loaderInner img {
    display: inline-block;
}

.loaderInner {
    text-align: center;
}

.loadingChecking {
    width: 100%;
    height: calc(100vh - 105px);
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.loadingChecking img {
    max-width: 100px;
}

.loadingChecking h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    margin: 0px;
    color: #2d3b55;
    margin-bottom: 10px;
}

.recomendedFlags {
    background: url(../images/starTagGreen.svg) no-repeat center center;
    width: 40px;
    height: 36px;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
}

.manageTableRow {
    width: 100%;
    display: flex;
    align-items: center;
}

.ManageImageText {
    padding-right: 17px;
}

.manageTableRow h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #414d55;
}

.manageTableRow p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #5f5f5f;
    letter-spacing: 0.1px;
    margin: 0;
}

.manageTableRow h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.1px;
    color: #969696;
}

.manageTableRow p strong {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #5f5f5f;
}

.manageTableRow .borderLeft {
    text-align: center;
    padding: 0 0.5rem;
}

.manTableCols.manageTwoText {
    width: 36%;
}

.borderLeft {
    border-left: 1px dashed #a2c0d4;
}

.manGrArrowTop {
    width: 18px;
    height: 18px;
    background: url(../images/pollyArrowTop.svg) no-repeat center center;
    vertical-align: middle;
    display: inline-block;
    background-size: 18px;
}

.manIconBtnAddPre {
    width: 26px;
    height: 26px;
    background: url(../images/manPlusGreen.svg) no-repeat center center;
    background-size: 26px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 13px;
}

.manIconBtnAddPre.manPrevBtn {
    background-image: url(../images/expendButtonGrey.svg);
}

.manTabTopTxt {
    width: 12%;
}

.ManIconButtons {
    display: flex;
}

// .manageTableRow .ManIconButtons   {height: 100%; padding:0.6rem 0;}
.manageTableRow {
    background: #fff;
    box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 14px;
    align-items: stretch;
    flex: 0 0 auto;
    height: 82px;
}

.manageTableRow>div {
    height: 100%;
    align-items: center;
    align-self: flex-start;
    display: flex;
    flex-wrap: wrap;
}

.manageTableRow .borderLeft {
    justify-content: center;
}

.manageTableRow>div.ManIconButtons {
    flex-wrap: inherit;
}

// .buttonTabs h2 {
//   line-height: inherit;
// }
.manageFirstSlider.investSlider {
    width: 100%;
}

.manageFirstSlider .savingSteps.step2 {
    right: 18px;
}

.manageFirstSlider .savingSteps.step3 {
    right: 15px;
}

.portfolioAllocationTab .ant-tabs,
.portfolioAllocationTab .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: inherit;
}

.recommendedTextTopPort {
    position: absolute;
    top: -40px;
    background-color: #37c597;
    transform: rotate(0);
    margin-left: -64px;
    padding: 6px;
    border-radius: 5px;
    left: 50%;
    top: -27px;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: #ffffff;
    border: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.ComapnyCardWrappersMain .CompanyListViewItm .companyCardHeader .brandinfo {
    max-width: 99%;
    width: 100%;
}

// .ComapnyCardWrappersMain .CompanyListViewItm .companyCardHeader{max-width: 37%;}
.ComapnyCardWrappersMain .CompanyListViewItm .DataSetings .dataItm {
    height: 100%;
}

// .flagTextContainer {padding-left: 50px;}
// .recomendedFlags {position: absolute; left: 5px;}
// .ComapnyCardWrappersMain .CompanyListViewItm .companyCardHeader{max-width: 37%;}
.DataSetings .dataItm:not(.DataSetings .dataItm:nth-last-child(2)) {
    flex-direction: column;
    display: flex;
    justify-content: center;
}

.ComapnyCardWrappersMain .CompanyListViewItm .companyCardHeader .brandinfo .brandName {
    max-width: calc(78% - 1rem);
}

.ComapnyCardWrappersMain .CompanyListViewItm .companyCardHeader .brandinfo .brandLogo {
    height: auto;
    width: 56px;
}

.ComapnyCardWrappersMain .CompanyListViewItm .companyCardHeader .brandinfo .brandLogo img {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

.portfolioCharts .currentLeft.rightRecom .curentPortLabel,
.portfolioCharts .currentLeft .curentPortLabel {
    left: -50%;
    right: -50%;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.portfolioAllocationTab .recomendedFlags {
    position: relative;
    top: -12px;
    left: -2%;
    width: 26px;
    height: 36px;
}

.portfolioAllocationTab .recommendedTextTopPort {
    // left: -50%;
    // right: -50%;
    // left: 20px;
    left: 10px;
    margin: auto;
    width: fit-content;
}

.portfolioAllocationTab .PortChartTextList {
    margin: 12px 0px 15px;
}

.portfolioAllocationTab .PortChartTextList {
    margin: 12px 0px 15px;
}

.apexcharts-legend {
    display: none !important;
}

.apexChartLegendsEnable .apexcharts-legend {
    display: block !important;
}

.apexChartLegendsEnable .portChartContainer {
    width: 100%;
}

.apexChartLegendsEnable .portfolioChart {
    width: auto;
    margin: 0 auto;
}

.portfolioChart {
    width: auto;
}

.portChartContainer {
    justify-content: center;
}

.portChartDonutTotalShow {
    position: absolute;
    width: 100%;
    text-align: center;
}

.portChartDonutTotalShow h2 {
    line-height: 125%;
    font-size: 20px;
    font-weight: 900;
    margin: 0 0 5px;
}
.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
    top: 0 !important;
}
.portDonutTotalValue {
    font-size: 15px;
    font-weight: 500;
}

.ComapnyCardWrappersMain .CompanyListViewItm .DataSetings .dataItm {
    max-width: 20%;
    width: 16.9%;
}

.investedPortfolio .ComapnyCardWrappersMain .CompanyListViewItm .DataSetings .dataItm {
    // width: 14.28%;
    // max-width: 14.28%;
    max-width: 16.28%;
    width: 16.28%;
}

.investedPortfolio .DataSetings .dataItm h6 {
    margin-top: 0.4rem;
    // word-break: break-all;
    word-break: inherit;
}


/**************** Chart *******************/

.portChartContainer {
    min-height: 200px;
    height: 200px;
}

.apexcharts-legend-text {
    width: 45px !important;
    overflow: hidden;
    display: inline-block;
}

.apexChartLegendsEnable .portChartDonutTotalShow {
    width: 72%;
}

.inputDisableField {
    border-color: transparent !important;
}

.borderNone {
    border: none;
    display: contents;
}

.paddingTopNone {
    margin-top: 0;
}

.DataSetings .dataItm h6 {
    margin-top: 0.4rem;
    word-break: break-all;
}

.DataSetings .dataItm:first-child {
    margin-left: auto;
}

.slideDots.SlideTwo:after {
    //progress bar removal
    background: #35b289;
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    // width: 430%;
    // height: 2px;
    // left: -370%;
    width: 210%;
    height: 2px;
    left: -142%;
    top: 19px;
}

.slideDots.SlideThree:after {
    //progress bar removal
    background: #35b289;
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 430%;
    height: 2px;
    left: -370%;
    top: 19px;
}

.slideDots.SlideOne {
    background-image: url(../images/OvalInvestmentActive.svg);
}

.slideDots.SlideTwo {
    background-image: url(../images/OvalInvestmentActive.svg);
}

.slideDots.SlideThree {
    background-image: url(../images/OvalInvestmentActive.svg);
}

.seffDirectedMain .DataSetings .dataItm:last-child {
    flex-direction: row !important;
}

.reimTypeCont.leftRebCont {
    cursor: pointer;
}

.reimTypeCont.leftRebCont.active {
    border: 3px solid #37c597;
}

.pendingInfo {
    background: url(../images/infoRed.svg) no-repeat center center;
    background-size: 20px;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 4px;
    vertical-align: middle;
}

.alertBoxRecipt {
    position: absolute;
    top: 76px;
    width: 100%;
    height: auto;
    z-index: 999;
}

.alertBoxRecipt .ant-alert {
    max-width: 420px;
    margin: auto;
}

.alertBoxReciptTable {
    position: absolute;
    right: 160px;
    top: 34vh;
    z-index: 9999;
    max-width: 420px;
}

.bankingScroll {
    height: calc(100vh - 240px);
    overflow: auto;
}

.ExpandViewBtn {
    color: #fff !important;
}

.editSettingDisable .PhoneProfile {
    pointer-events: none;
}

.expenseTableStatus {
    display: flex;
    align-items: center;
}

.previewReceipt {
    background-color: #37c597;
    padding: 10px 10px;
    color: #fff;
    margin-left: 9px;
    border-radius: 4px;
}

.newEyeIcon {
    margin-left: 15px;
}

// #expenseForm div#feedback\ error{
//   display: none;
// }
// #expenseForm input.ant-input:focus ~ div#feedback\ error {
//     display: block;
// }
input.inputIconsDollar::-webkit-outer-spin-button,
input.inputIconsDollar::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"].inputIconsDollar {
    -moz-appearance: textfield;
}

.invalid-feedback {
    color: red;
}

.downloadImgIcon {
    position: absolute;
    top: 15px;
    right: 40px;
    color: #fff;
    font-size: 24px;
    background: #37c597;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    z-index: 99;
}

.downloadRecpt button.ant-modal-close {
    position: relative;
    display: flex;
    margin-left: auto;
    // background: #ccc;
    // color: #333;
}

.downloadRecpt button.ant-modal-close span.ant-modal-close-x {
    font-size: 22px;
}

.downloadRecpt .ant-modal-body {
    position: relative;
    padding-top: 0;
}

.downloadRecpt .ant-modal-content {
    min-height: 200px;
}

.noReceiptImage {
    font-size: 20px;
    font-weight: 600;
    color: red;
}

.imageLoading {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 9;
}

.antLoaderReceipt {
    position: absolute;
    top: 33.5%;
    bottom: auto;
    left: 50%;
    transform: translate(-50%);
    z-index: -1;
}

.antLoaderReceipt span {
    font-size: 72.5px !important;
}

.datePickerCal {
    position: relative;
    z-index: 99;
}

input[type="date"].datePickerCal::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.datePickerCal:after {
    background: url(../images/DatePicIcon.png) no-repeat center center;
    background-size: calc(18px);
    width: 20px;
    height: 20px;
    position: absolute;
    content: "";
    right: 5px;
    top: 7px;
    z-index: -1;
}

.TotalExpenses {
    background: linear-gradient(196.91deg, #ffc201 7.23%, #ff9801 77.51%);
}

// .iframeModal { width: 100%;
//   transform-origin: 85px 43px;
//   overflow: hidden;
//   height: -webkit-fill-available;}
//   .iframeModal .ant-modal-content {
//     width: 100vw;
//     height: calc(100vh - 20px) !important;
//     top: 0;
//   }
//   .iframeModal  .ant-modal-centered::before {
//     content: unset;
//   }
//   .iframeModal  .ant-modal-footer {
//     display: none;
//   }
.iframeModal .ant-modal-close {
    right: -11px;
    top: -12px;
}

.transactionHoverTabs {
    position: absolute;
    top: 42px;
    z-index: 9999;
    background: #fff;
    left: 15%;
    padding: 10px;
    border-radius: 5px;
    display: none;
}

.transactionHoverTabs a {
    display: block;
    color: #2d3b55;
    margin: 0 0 15px;
}

.ant-tabs.investmentTabs>.ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: visible;
}

.ant-tabs.investmentTabs>.ant-tabs-nav {
    z-index: 9999;
}

.ant-tabs-tab+.ant-tabs-tab:hover .transactionHoverTabs {
    display: block;
}

.hoverMenu {
    display: block;
    width: 100%;
    height: 100%;
}

.totalDate {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.transactionHoverTabs a:hover {
    color: #37c597;
}

.ant-tabs-tab a {
    color: #898e94;
}

.ant-tabs-tab a:hover {
    color: #37c597 !important;
}

.ant-tabs-tab.ant-tabs-tab-active a {
    color: #2d3b55 !important;
}

.expenseTable tr td:last-child {
    position: relative;
    z-index: 99999999;
}

.letSideProfile {
    z-index: 99999;
}

.whiteSpinner .ant-spin-dot-item {
    background-color: #fff;
}

.whiteSpinner .ant-spin {
    color: #fff;
}

.slideDots.SlideTwo:after,
.slideDots.SlideThree:after {
    content: none;
}
.admincontrolsPage .users{height: 500px; overflow-y: auto;}
.admincontrolsPage .browse-btn{margin: 0;}

@media (min-width: 728px) {
    .profileFormEdit.profileFormEdit input,
    .profileFormEdit.profileFormEdit .ant-select {
        // max-width: 75%;
        max-width: 315px;
    }
}

.profileSection {
    .ant-input[disabled] {
        background-color: transparent;
        border: 0;
        text-align: end;
    }
}

@media (max-width: 1098px) {
    .MoveMoneyBtn .onlyBorder .iconShopHSA,
    .MoveMoneyBtn .fundingAmountRightDiv .iconShopHSA {
        background-size: 55px;
        margin-right: 5px;
    }
    .MoveMoneyBtn {
        .hsaButtonBorder {
            padding: 9px;
        }
        .priceHSAGreen {
            font-size: 20px;
        }
    }
    .InvestMentCardInner h3 {
        font-size: 1.16rem;
        font-weight: 700;
        color: #fff;
        min-height: 56px;
        margin-bottom: 0.1rem;
    }
}

.counterTitle h2 {
    font-size: 20px;
}

.primary2 {
    background: linear-gradient(145deg, #229ee5 7.56%, #1875c5 91.99%);
}

.investmetsTabs {
    .ant-tabs-top>.ant-tabs-nav::before,
    .ant-tabs-bottom>.ant-tabs-nav::before,
    .ant-tabs-top>div>.ant-tabs-nav::before,
    .ant-tabs-bottom>div>.ant-tabs-nav::before {
        border-bottom: none;
    }
}

// notification banners
.notification-banner-success{
    width: 800px;
    height: 50px;
    display: flex;
    align-items: center;
}

.notification-banner-error{
    width: 800px;
    height: 50px;
    display: flex;
    align-items: center;
}

.notification-banner-warning{
    width: 800px;
    height: 50px;
    display: flex;
    align-items: center;
}

.notification-banner-info{
    width: 800px;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #80E6FD;
    border: 2px solid #1890ff;
    border-radius: 6px;
}
